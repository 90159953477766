import React, {useRef} from 'react';
import {GoAlert} from 'react-icons/go';
import { CircularGaugeComponent, AxesDirective, AxisDirective, Inject, PointersDirective, PointerDirective, RangesDirective, RangeDirective, Annotations, AnnotationsDirective, AnnotationDirective } from '@syncfusion/ej2-react-circulargauge';
import {ProjectColors} from "../../General/projectColors";
import Circle from "../Circle/Circle";

/**
 * Props:
 * value (Integer): The Value
 * valueSuffix (String): Suffix to display after the value
 * minimum (Integer): Minimum Value
 * maximum (Integer): Maximum Value
 * showAlert (Boolean): Determines whether to show the Alert symbol or not
 * ranges (Array): Array describing the Ranges displayed on the gauge (Empty array will show a gray gauge)
 * [
 *  {
 *      start: Range starting value
 *      end: Range end value
 *      color: Color of the range on the Gauge
 *  }
 * ]
 * isPercentage (Boolean): Determines whether the values displayed are percentages or not
 */
export default function CircularFlowrateGauge(props) {
    const RANGE_PADDING_VAL = 1.1;
    const valueLabel = (props.isPercentage ? parseFloat(props.value).toFixed(2) * 100 : parseFloat(props.value).toFixed(2));
    const valueDisplay = `<div><span style="font-size:16px; color:#424242; font-family:Poppins">${valueLabel || 0} ${props.valueSuffix || ''}</span></div>`
    const alertIcon = useRef(() => {
        const iconSize = 30;
        return (
            <div style={{display: 'block', margin: 'auto', position: 'relative', right: `${iconSize / 2}px`}}>
                <GoAlert size={iconSize} style={{ color: ProjectColors.Red }} />
            </div>
        )
    })

    return (
        <div>
            <h6 style={{fontWeight: 600}}>{props.title}</h6>
            {props.shiftName ? <p style={{fontSize: 13}}>{`${props.shiftName} shift`}</p> : <></> }
            <p style={{fontSize: 13}}>{props.subtitle}</p>
            <CircularGaugeComponent style={{width: props.width, height: '25vh'}}>
                <Inject services={[Annotations]} />
                <AxesDirective>
                    <AxisDirective
                        showLastLabel
                        startAngle={210}
                        radius='100%'
                        endAngle={150}
                        minimum={(props.ranges && props.ranges.length > 0) ? props.minimum : Math.floor(props.minimum / RANGE_PADDING_VAL)}
                        maximum={(props.ranges && props.ranges.length > 0) ? props.maximum : Math.ceil(props.maximum * RANGE_PADDING_VAL)}
                        majorTicks={{ height: 10, offset: 5 }}
                        minorTicks={{ height: 5, offset: 5 }}
                        labelStyle={{ position: 'Inside', format: props.isPercentage ? 'p0' : '', font: { size: '12px', fontFamily: 'Poppins', fontStyle: 'Regular' }, useRangeColor: false }}>

                        <PointersDirective>
                            <PointerDirective value={props.value || 0} animation={{enable: false}} radius='100%' pointerWidth={8} needleTail={{ length: '18%' }} cap={{ radius: 7 }} />
                            { props.target !== undefined && <PointerDirective value={props.target || 0} animation={{enable: false}} radius='100%' color={ProjectColors.LightBlue} type="Marker" markerShape="InvertedTriangle" markerHeight={15} markerWidth={15} /> }
                        </PointersDirective>

                        { (!props.ranges || props.ranges.length === 0) &&
                            <RangesDirective>
                                <RangeDirective key={`${props.id}-range-directive-1`} start={Math.floor(props.minimum / RANGE_PADDING_VAL)} end={props.minimum} color={ProjectColors.Red} />
                                <RangeDirective key={`${props.id}-range-directive-2`} start={props.minimum} end={props.maximum} color={ProjectColors.Green} />
                                <RangeDirective key={`${props.id}-range-directive-3`} start={props.maximum} end={Math.ceil(props.maximum * RANGE_PADDING_VAL)} color={ProjectColors.Red} />
                            </RangesDirective>
                        }

                        { (props.ranges && props.ranges.length > 0) &&
                            <RangesDirective>
                                {
                                    props.ranges.map((range, index) => {
                                        return (
                                            <RangeDirective key={`${props.id}-range-directive-${index}`} start={range.start} end={range.end} color={range.color}/>
                                        )
                                    })
                                }
                            </RangesDirective>
                        }

                        <AnnotationsDirective>
                            <AnnotationDirective  content={valueDisplay} angle={180} zIndex='1' radius='40%' />
                            { props.showAlert && <AnnotationDirective content={alertIcon.current } angle={180} zIndex='1' radius='60%' /> }
                        </AnnotationsDirective>

                    </AxisDirective>
                </AxesDirective>
            </CircularGaugeComponent>
            {props.withCircle ?
                <Circle bgColor={props.color}/>
                : <></>
            }
        </div>
    )
}