import Cookies from 'js-cookie';
import {Expiring} from "./expiring";

const axios = require('axios').default;
const Tokens = require('csrf')

export const LOGIN_CSRF_COOKIE = 'login-csrf';
export const ACCESS_TOKEN_COOKIE = 'access-token';
export const REFRESH_TOKEN_COOKIE = 'refresh-token';
export const TOKEN_TYPE_COOKIE = 'token-type';
export const COOKIE_OPTIONS = {sameSite: 'Strict', secure: process.env.REACT_APP_SECURE_COOKIES === 'true'};
export const EXPIRY_WARNING_PERIOD = 10;

/** Get access token, refresh it if required.
 * @returns {Expiring} Wrapped access token.
 */
export function refreshAccessTokenIfNeeded() {
    const tokens = new Tokens();
    const csrf = tokens.secretSync()

    const accessToken = Expiring.fromCookie(ACCESS_TOKEN_COOKIE);
    if (!accessToken.expired) {
        return accessToken;
    }
    const refreshToken = Expiring.fromCookie(REFRESH_TOKEN_COOKIE);
    if (refreshToken.expired) {
        return null; // Nothing to do except re-authenticate
    }

    const data = {
        refreshToken: refreshToken.value,
        clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
        csrf
    };

    const url = new URL(`${process.env.REACT_APP_OAUTH_SERVER_URL}${process.env.REACT_APP_OAUTH_CLIENT_REFRESH_PATH}`);
    axios.post(url.toString(), data, {})
        .then(response => {
            const receivedCsrf = response.data.csrf;
            // Expect a matching CSRF value
            if (receivedCsrf !== csrf) {
                return csrfMismatch();
            }

            const newAccessToken = Expiring.fromNow(response.data.accessToken, response.data.accessTokenLifetime);
            const newRefreshToken = Expiring.fromNow(response.data.refreshToken, response.data.refreshTokenLifetime);

            if (storeAccessToken(newAccessToken, newRefreshToken, receivedCsrf, csrf)) {
                return newAccessToken;
            } else {
                return null;
            }
        })
        .catch(reason => {
            return null;
        });
}

/** Store access and refresh tokens.
 * @param accessToken {Expiring}
 * @param refreshToken {Expiring}
 * @param csrf Received CSRF
 * @param expectedCsrf Expected CSRF
 * @returns {boolean} true if the operation succeeds
 */
export function storeAccessToken(accessToken, refreshToken, csrf, expectedCsrf) {
    try {
        // Expect a matching CSRF value
        if (csrf !== expectedCsrf) {
            return storeTokenFailed(new Error("CSRF mismatch"));
        }

        accessToken.storeAsCookie(ACCESS_TOKEN_COOKIE, COOKIE_OPTIONS);
        refreshToken.storeAsCookie(REFRESH_TOKEN_COOKIE, COOKIE_OPTIONS);
        Cookies.set(TOKEN_TYPE_COOKIE, "Bearer", {...COOKIE_OPTIONS});
        Cookies.remove(LOGIN_CSRF_COOKIE);

        return true;
    } catch (exception) {
        return storeTokenFailed(exception);
    }
}

function csrfMismatch() {
    console.error(new Error("CSRF mismatch"));
}

function storeTokenFailed(error) {
    console.error(error);
    Cookies.remove(ACCESS_TOKEN_COOKIE);
    Cookies.remove(REFRESH_TOKEN_COOKIE);
    Cookies.remove(TOKEN_TYPE_COOKIE);
    Cookies.remove(LOGIN_CSRF_COOKIE);

    window.location.replace(process.env.REACT_APP_SIGNED_OUT_URL);
    return false;
}

axios.interceptors.request.use((config) => {
    if (config && !config.url.endsWith('token')) {
        config.headers.Authorization = createAuthHeader();
    }

    return config;
})

export const disconnectUser = () => {
    window.location.replace(process.env.REACT_APP_SIGNED_OUT_URL);
}

/**
 * Create an authorization header value
 * @param accessToken {Expiring}
 * @returns {string}
 */
export function createAuthHeaderFromAccessToken(accessToken) {
    return `${Cookies.get(TOKEN_TYPE_COOKIE)} ${accessToken.value}`;
}

export function createAuthHeader() {
    const accessToken = refreshAccessTokenIfNeeded();
    return createAuthHeaderFromAccessToken(accessToken);
}

export const handleError = (error) => {
    if (!error.response) {
        console.error(error);
        return;
    }

    // Handle Error 400 by redirecting to the login page
    if (error.response.status === 400) {
        disconnectUser();
    }
    // Handle Error 401 by checking if the access token is expired
    else if (error.response.status === 401) {
        // axios.interceptors.request.use((config) => {
        //     if (Expiring.fromCookie(ACCESS_TOKEN_COOKIE).expired) {
        //         config.headers.Authorization = createAuthHeader();
        //     } else {
        //         debugger
        //         disconnectUser();
        //     }
        //
        //     return config;
        // })
    } else {
        console.error(error);
    }
}

export default axios;