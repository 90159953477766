import React, {useContext, useEffect, useRef, useState} from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { store } from '../../Store/store';
import moment from 'moment';
import AnomaliesTable from '../../Components/AnomaliesTable/AnomaliesTable';
import { isEmptyObject } from 'jquery';
import axios, { handleError } from '../../Axios/axiosConfig'
import {getProductionLineId, timeFormatter} from '../../General/general';
import {SiMicrosoftexcel} from "react-icons/si";
import { CSVLink } from "react-csv";
import FlowrateChartGroup from "../../Components/FlowrateChartGroup/FlowrateChartGroup";

import './ShiftReportPage.css'

export default function ShiftReportPage() {
    const globalState = useContext(store);
    const serverUrl = useRef(`${globalState.state.serverUrl}/production_line/${getProductionLineId(globalState)}`);
    const params = new URLSearchParams(window.location.search);
    const shiftReportId = parseInt(params.get('shiftReportId'));
    let shiftReportDate = moment(new Date(params.get('shiftDate')));
    let shiftReportNextDate = shiftReportDate.clone().add(1, 'days');

    shiftReportDate = shiftReportDate.format().slice(0, -6) + 'Z';
    shiftReportNextDate = shiftReportNextDate.format().slice(0, -6) + 'Z';

    const [charts, setCharts] = useState([]);
    const [chartsUnitSymbol, setChartsUnitSymbol] = useState(null);

    const [isDoneLoading, setIsDoneLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [anomalies, setAnomalies] = useState([]);
    const [stageChanges, setStageChanges] = useState([]);
    const [csvData, setCsvData] = useState([]);

    const stageChangesColumns = [
        { dataField: 'product', text: 'Product'},
        { dataField: 'stageName', text: 'Stage'},
        { dataField: 'startTime', text: 'Start Time'},
        { dataField: 'endTime', text: 'End Time'},
        { dataField: 'duration', text: 'Duration (HH:mm:ss)'},
        { dataField: 'totalWaterUsed', text: 'Total Water Used (m³)'},
        { dataField: 'totalWaterUsedByProduced', text: 'Total Water Used (L/kg)'},
        { dataField: 'totalProducedProduct', text: 'Total Produced Product (Kg)'},
    ]

    const addTimezoneToHours = (value) => {
        let [h, m] = value.split(':');

        return `${parseInt(h) + 2}:${m}`;
    }

    const calcTimeDiff = (startDate, endDate) => {
        function z(n) { return (n < 10? '0' : '') + n; }

        let secs = (new Date(endDate) - new Date(startDate)) / 1000;
        secs = Math.abs(secs);
        return `${z(secs / 3600 | 0)}:${z((secs % 3600) / 60 | 0)}:${z(secs % 60)}`;
    }

    const loadShiftReport = async () => {
        await axios.get(serverUrl.current + `/workshifts/reportBetween?workshiftId=${shiftReportId}&fromDate=${shiftReportDate}&toDate=${shiftReportNextDate}`).then(response => {

            // Init Objects
            let workShift = response.data.workShift;
            let anomalies = [];
            let stageChanges = [];

            setTitle(`Shift Report for ${moment(shiftReportDate).format('ll')}, ${workShift.label}, ${addTimezoneToHours(workShift.startTime)} - ${addTimezoneToHours(workShift.endTime)}`)

            // Handle Anomalies
            response.data.anomalies.forEach(currAnomaly => {
                if (currAnomaly.status !== "CLEAR") {
                    anomalies.push({
                        id: currAnomaly.id,
                        message: currAnomaly.message,
                        status: currAnomaly.status,
                        detectionTime: timeFormatter(currAnomaly.detectionTime),
                        endTime: timeFormatter(currAnomaly.endTime)
                    });
                }
            });

            setAnomalies(anomalies);

            setChartsUnitSymbol(response.data.sensors[0]?.unitSymbol);

            setCharts(response.data.sensors.map((sensor, index) => {
                return {
                    name: sensor.label,
                    data: sensor.timeSeriesData
                }
            }))

            // Handle Production Stage Changes
            response.data.stageChanges.forEach((currStageChange, index) => {
                stageChanges.push({
                    key: index,
                    product: currStageChange.product,
                    stageName: currStageChange.stageName,
                    totalWaterUsed: parseFloat(currStageChange.totalWaterUsed).toFixed(1),
                    totalWaterUsedByProduced: parseFloat(currStageChange.totalWaterUsedByProduced).toFixed(1),
                    totalProducedProduct: parseFloat(currStageChange.totalProduct).toFixed(1),
                    startTime: timeFormatter(currStageChange.startTime),
                    endTime: timeFormatter(currStageChange.endTime),
                    duration: calcTimeDiff(currStageChange.startTime, currStageChange.endTime)
                });
            });

            setStageChanges(stageChanges);
            setCsvData(buildCsvData(response.data.sensors));
            setIsDoneLoading(true);
        }).catch(error => handleError(error));
    }

    const buildCsvData = (sensors) => {
        let data = ['Date'];
        sensors.forEach((sensor) => {
            data.push(sensor.label);
        })
        let csvData = [data];
        let totalWater = sensors[sensors.length-1].timeSeriesData;
        for (let index = 0; index < totalWater.length; index++) {
            let date = moment.utc(totalWater[index][0]).tz('Europe/Brussels').format('DD MM YYYY hh:mm:ss');
            let cell = [date];
            sensors.forEach((sensor) => {
                cell.push(sensor.timeSeriesData[index][1]);
            })
            csvData.push(cell);
        }

        return csvData;
    }


    useEffect(() => {
        serverUrl.current = `${globalState.state.serverUrl}/${getProductionLineId(globalState)}`;
        loadShiftReport();
    }, [])

    return (
        <Container fluid>
            <h5>{title}</h5>
            {isDoneLoading && <CSVLink data={csvData} filename={`${title}.csv`}>
                <Button variant="success" className="export-to-csv-button" style={{position: 'inherit', marginLeft: '-97%'}}>
                    <SiMicrosoftexcel style={{color: 'white'}}/>
                </Button>
            </CSVLink> }

            <Row>
                <Col>
                    <FlowrateChartGroup showLegend charts={charts} unitSymbol={chartsUnitSymbol} showSpinnerOnLoad />
                </Col>
            </Row>
            <Row>
                <Col>
                    { /* Details Table */ }
                    { !isEmptyObject(stageChanges) &&
                    <>
                        <h5>Process Stages</h5>
                        <BootstrapTable keyField="id" data={stageChanges} columns={stageChangesColumns} />
                    </>
                    }
                </Col>
            </Row>
            <Row>
                <Col>
            {/*        { /* Anomalies Table */ }
                    <AnomaliesTable data={anomalies} />
                </Col>
            </Row>
        </Container>
    )
}