import React, {useState, useContext, useEffect, useRef} from 'react';
import {Container, Row, Col, Button} from 'react-bootstrap'
import {default as BootstrapSpinner} from 'react-bootstrap/Spinner'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {FaSync} from 'react-icons/fa'
import {store} from '../../Store/store'
import axios, {handleError} from '../../Axios/axiosConfig'
import moment from 'moment';

import './PotatoChipsHomePage.css'
import {ProjectColors} from "../../General/projectColors";

import {timeFormatter, numberWithCommas, timeFormatterNoDate, getProductionLineId} from '../../General/general'
import ShiftsReportsTable from "../../Components/ShiftsReportsTable/ShiftsReportsTable";
import CircularFlowrateGauge from "../../Components/CircularFlowrateGauge/CircularFlowrateGauge";
import FlowrateChartGroup from "../../Components/FlowrateChartGroup/FlowrateChartGroup";
import AnomaliesTable from "../../Components/AnomaliesTable/AnomaliesTable";

export default function PotatoChipsHomePage() {
    const globalState = useContext(store);
    const refreshRate = globalState.state.refreshRate;
    const selectedProductionLineId = getProductionLineId(globalState);
    const serverUrl = useRef(`${globalState.state.serverUrl}/${selectedProductionLineId}`);
    const newServerUrl = useRef(`${globalState.state.serverUrl}/production_line/${selectedProductionLineId}`);
    const selectedShiftDate = useRef(null);

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingWorkShifts, setIsLoadingWorkShifts] = useState(false);

    const [currentProductionFlowRates, setCurrentProductionFlowRates] = useState({});
    const [averageShiftConsumption, setAverageShiftConsumption] = useState({});
    const [averageDailyConsumption, setAverageDailyConsumption] = useState({});
    const [sensorCharts, setSensorCharts] = useState([]);
    const [computedChart, setComputedChart] = useState([]);
    const [sensorChartsUnitSymbol, setSensorChartsUnitSymbol] = useState(null);
    const [computedChartsUnitSymbol, setComputedChartsUnitSymbol] = useState([]);
    const [shiftsDate, setShiftsDate] = useState(moment());
    const [shiftsReportData, setShiftsReportData] = useState([]);
    const [anomaliesData, setAnomaliesData] = useState([]);
    const [ledColor, setLedColor] = useState(ProjectColors.Green);
    const [cancelTokens, setCancelTokens] = useState([]);

    let fromDate, toDate;

    const updateTimeFrame = () => {
        let now = new Date();
        toDate = moment(now).utc().format()
        fromDate = moment(now).utc().subtract(8, 'hours').format()
    }


    const addTimezoneToHours = (value) => {
        let [h, m] = value.split(':');

        return `${(parseInt(h) + 2) % 24}:${m}`;
    }

    /**
     * Inits the Anomalies table
     */
    const getAnomalies = () => {
        axios.get(`${newServerUrl.current}/anomalies/last`).then(response => {
            let anomaliesDataArray = [];

            response.data.forEach((currVal) => {
                anomaliesDataArray.push({
                    id: currVal.id,
                    message: currVal.message,
                    detectionTime: timeFormatter(currVal.startTime)
                })
            })
            if (response.data.length > 0) {
                setLedColor(ProjectColors.Red);
            } else {
                setLedColor(ProjectColors.Green);
            }

            // Sorting the results by date (most recent first)
            anomaliesDataArray.sort(function (a, b) {
                return new Date(b.detectionTime) - new Date(a.detectionTime);
            });

            setAnomaliesData(anomaliesDataArray);
        }).catch(error => handleError(error))
    }


    const getAnomaliesDummy = () => {
        setAnomaliesData([{
            id: 1,
            message: "Background flow of 273 L/h detected",
            detectionTime: "July 28 2021, 10:52PM"
        },
            {
                id: 2,
                message: "Valve performance alert. Check valves 3707, 3809, or 3308",
                detectionTime: "Aug 4 2021, 12:10PM"
            },
            {
                id: 3,
                message: "Flowrate too high/low by 2,000 L/h",
                detectionTime: "Aug 11 2021, 08:45AM"
            }])
        setLedColor(ProjectColors.Red)
    }
    /**
     * Real-Time consumption data
     * Inits data for main gauge #1
     */
    const getProductionFlowrates = () => {
        axios.get(`${serverUrl.current}/productionFlowRate/latest`).then(response => {
            let flowratesData = {
                id: 'production-flowrate-gauge',
                title: `${response.data.product_name || ''} / Stage ${response.data.stage_name || ''} ${response.data.stage_description || ''}`,
                subtitle: timeFormatter(response.data.timestamp),
                value: response.data.totalAvg,
                target: response.data.flowRate,
                minimum: response.data.minFlowRate,
                maximum: response.data.maxFlowRate
            }

            setCurrentProductionFlowRates(flowratesData);
        }).catch(error => {
            handleError(error);
            setCurrentProductionFlowRates({
                id: 'production-flowrate-gauge',
                title: null,
                subtitle: null,
                value: 0,
                minimum: 0,
                maximum: 0,
            });
        })
    }

    /**
     * Average Shift Consumption
     * Inits data for main gauge #2
     */
    const getAverageShiftConsumption = () => {
        axios.get(`${serverUrl.current}/productionFlowRate/meanShiftConsumption`).then(response => {
            let flowratesData = {
                id: 'avg-shift-flowrate-gauge',
                title: `Average Shift Consumption`,
                shiftName: response.data.shiftName,
                subtitle: `${response.data.product_name}, Started ${timeFormatterNoDate(response.data.startedFrom)}`,
                // subtitle: timeFormatter(response.data.timestamp),
                value: response.data.totalAvg,
                target: response.data.flowRate,
                minimum: response.data.minFlowRate,
                maximum: response.data.maxFlowRate
            }

            setAverageShiftConsumption(flowratesData);
        }).catch(error => {
            handleError(error);
            setAverageShiftConsumption({
                id: 'avg-shift-flowrate-gauge',
                title: null,
                subtitle: null,
                shiftName: null,
                value: 0,
                minimum: 0,
                maximum: 0
            });
        })
    }

    /**
     * Average Daily Consumption
     * Inits data for main gauge #3
     */
    const getAverageDailyConsumption = () => {
        axios.get(`${serverUrl.current}/productionFlowRate/meanDailyConsumption`).then(response => {
            let flowratesData = {
                id: 'avg-daily-flowrate-gauge',
                title: `Average Daily Consumption`,
                shiftName: response.data.shiftName,
                subtitle: `${response.data.product_name}, Started ${timeFormatterNoDate(response.data.startedFrom)}`,
                // subtitle: timeFormatter(response.data.timestamp),
                value: response.data.totalAvg,
                target: response.data.flowRate,
                minimum: response.data.minFlowRate,
                maximum: response.data.maxFlowRate,
            }

            setAverageDailyConsumption(flowratesData);
        }).catch(error => {
            handleError(error)
            setAverageDailyConsumption({
                id: 'avg-daily-flowrate-gauge',
                title: null,
                shiftName: null,
                subtitle: null,
                value: 0,
                minimum: 0,
                maximum: 0
            });
        })
    }

    /**
     * Inits Hard & Soft Water data as a TimeSeries chart
     */
    const getSensorsData = (currentGlobalState) => {
        const currentProductionLineId = getProductionLineId(currentGlobalState);
        const currentServerUrl = `${currentGlobalState.state.serverUrl}/production_line/${currentProductionLineId}`;
        let newCancelTokens = cancelTokens;
        // removes tokens from the array to prevent array from getting too big for memory to hold
        if (newCancelTokens.length === 20) {
            newCancelTokens.splice(0, 10);
        }
        newCancelTokens.push(axios.CancelToken.source());
        setCancelTokens(newCancelTokens);
        let cancelToken = newCancelTokens[newCancelTokens.length - 1];
        axios.get(`${currentServerUrl}/flowDetails/between?fromDate=${fromDate}&toDate=${toDate}`, {cancelToken: cancelToken.token}).then(response => {
            setSensorChartsUnitSymbol(response.data.sensors[0]?.unitSymbol);
            setSensorCharts(response.data.sensors.map((sensor, index) => {
                return {
                    name: sensor.label,
                    data: sensor.timeSeriesData
                }
            }))

            setComputedChartsUnitSymbol(response.data.computed.map((compute) => {
                return {
                    name: compute.label,
                    unitSymbol: compute.unitSymbol
                }
            }))

            setComputedChart(response.data.computed.map((compute) => {
                return {
                    name: compute.label,
                    data: compute.timeSeriesData,
                    unitSymbol: compute.unitSymbol
                }
            }))

        }).catch(error => {
            handleError(error);
            setSensorCharts([]);
        })
    }

    /**
     * Retrieves the WorkShifts according to the selected date in the DatePicker
     */
    const getWorkShifts = () => {
        let fromDate = shiftsDate.clone().subtract(1, 'days').utc().format().substr(0, 11) + '03:00:00Z'
        let toDate = shiftsDate.clone().add(2, 'days').utc().format().substr(0, 11) + '03:00:00Z'
        if (selectedShiftDate?.current?.props?.selected) {
            const currentSelectedDate = moment(selectedShiftDate.current.props.selected);
            fromDate = currentSelectedDate.subtract(1, 'days').utc().format().substr(0, 11) + '03:00:00Z'
            toDate = currentSelectedDate.add(2, 'days').utc().format().substr(0, 11) + '03:00:00Z'
        }

        axios.get(`${serverUrl.current}/workshifts/summaryBetween?fromDate=${fromDate}&toDate=${toDate}`).then(response => {
            let shiftsReportData = [];

            response.data.forEach(currShift => {
                if (new Date(`${currShift.date} ${currShift.startTime}`) <= moment().utc()) {
                    shiftsReportData.push({
                        id: `${currShift.id}_${currShift.date}`,
                        displayName: currShift.name,
                        totalWaterUsed: numberWithCommas(parseFloat(currShift.totalWaterUsed).toFixed(3)),
                        totalWaterUsedByProduced: numberWithCommas(parseFloat(currShift.totalWaterUsedByProduced).toFixed(3)),
                        totalProducedProduct: numberWithCommas(parseFloat(currShift.totalProduct).toFixed(3)),
                        startTime: addTimezoneToHours(currShift.startTime),
                        endTime: addTimezoneToHours(currShift.endTime),
                        date: currShift.date
                    })
                }
            })

            setShiftsReportData(shiftsReportData);
            setIsLoadingWorkShifts(false);
        }).catch(error => {
            handleError(error)
            setShiftsReportData([]);
        })
    }

    /**
     * Calls every necessary request to display all the relevant data
     */
    const getAllData = (currentGlobalState) => {
        try {
            setIsLoading(true);
            updateTimeFrame();
            getProductionFlowrates();
            getAverageShiftConsumption();
            getAverageDailyConsumption();
            getSensorsData(currentGlobalState);
            getWorkShifts();
            getAnomalies();
            // getAnomaliesDummy();

        } catch (error) {
            handleError(error);
        } finally {
            setIsLoading(false);
        }
    }

    // Refresh the display every x seconds (Default is every 1 minute)
    useEffect(() => {
            const intervalId = setInterval(() => {
                getAllData(globalState);
            }, refreshRate)

            return () => clearInterval(intervalId);
        }, [globalState, cancelTokens]
    );

    useEffect(() => {
        // when production line changes we want to cancel any pending requests from previous line
        if (cancelTokens.length > 0) {
            cancelTokens.forEach(token => {
                token.cancel("Operation canceled due to new request.");
            })
        }
        setComputedChart([]);
        setSensorCharts([]);
        serverUrl.current = `${globalState.state.serverUrl}/${getProductionLineId(globalState)}`;
        setSensorCharts([]);
        getAllData(globalState);

        setIsLoadingWorkShifts(true);
        getWorkShifts();
    }, [globalState.state.selectedProductionLine])

    // Load WorkShifts data on setting shifts date
    useEffect(() => {
        setIsLoadingWorkShifts(true);
        getWorkShifts();
    }, [shiftsDate])

    return (
        <>
            <br/>
            <Container fluid>
                {/*<Button className="refresh-button" variant="primary" disabled={isLoading} onClick={() => getAllData()}>*/}
                {/*    { isLoading ? <BootstrapSpinner animation="border" size="sm"/> : <FaSync/> }*/}
                {/*</Button>*/}
                <Row>
                    <Col xs={4}>
                        <CircularFlowrateGauge
                            id={currentProductionFlowRates.id}
                            title={currentProductionFlowRates.title}
                            subtitle={currentProductionFlowRates.subtitle}
                            value={currentProductionFlowRates.value}
                            target={currentProductionFlowRates.target}
                            valueSuffix={'m³/h'}
                            minimum={currentProductionFlowRates.minimum}
                            maximum={currentProductionFlowRates.maximum}
                            withCircle color={ledColor}/>
                    </Col>
                    <Col xs={4}>
                        <CircularFlowrateGauge
                            id={averageShiftConsumption.id}
                            title={averageShiftConsumption.title}
                            subtitle={averageShiftConsumption.subtitle}
                            shiftName={averageShiftConsumption.shiftName}
                            value={averageShiftConsumption.value}
                            target={averageShiftConsumption.target}
                            valueSuffix={'m³/h'}
                            minimum={averageShiftConsumption.minimum}
                            maximum={averageShiftConsumption.maximum}/>
                    </Col>
                    <Col xs={4}>
                        <CircularFlowrateGauge
                            id={averageDailyConsumption.id}
                            title={averageDailyConsumption.title}
                            subtitle={averageDailyConsumption.subtitle}
                            shiftName={averageDailyConsumption.shiftName}
                            value={averageDailyConsumption.value}
                            target={averageDailyConsumption.target}
                            valueSuffix={'m³/h'}
                            minimum={averageDailyConsumption.minimum}
                            maximum={averageDailyConsumption.maximum}/>
                    </Col>
                </Row>

                <Row>
                    <Col xs={6} style={{top: '2vh', position: 'relative'}}>
                        <h6><b>Shift Report Table</b></h6>
                        <div style={{margin: '2vh'}}>
                            <DatePicker ref={selectedShiftDate} selected={shiftsDate._d} dateFormat="dd/MM/yy" onChange={(date) => {
                                setShiftsDate(moment(date))
                            }}/>
                        </div>
                        <ShiftsReportsTable data={shiftsReportData} isLoading={isLoadingWorkShifts}/>
                        <AnomaliesTable data={anomaliesData} hideStatus/>
                    </Col>
                    <Col xs={6}>
                        <Row>
                            <Col>
                                <FlowrateChartGroup showLegend charts={sensorCharts} unitSymbol={sensorChartsUnitSymbol} yAxisTitle={"Flowrate"} showSpinnerOnLoad/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FlowrateChartGroup showLegend multipleYAxis charts={computedChart} showSpinnerOnLoad/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
