import {Redirect, Route, Switch} from "react-router-dom";
import HomePageContainer from "./Pages/HomePage/HomePageContainer";
import ValveStatusPage from "./Pages/ValveStatusPage/ValveStatusPage";
import ShiftReportPage from "./Pages/ShiftReportPage/ShiftReportPage";
import SettingsPage from "./Pages/SettingsPage/SettingsPage";
import UploadStatsPage from "./Pages/UploadStatsPage/UploadStatsPage";
import SignedOutPage from "./Pages/SignedOut/SignedOutPage";
import AboutPage from "./Pages/AboutPage";
import React from "react";
import WeeklyTrendPage from "./Pages/WeeklyTrendPage/WeeklyTrendPage";

export default function Routes() {
    return(
        <Switch>
        <Route exact path='/'>
            <Redirect to='/dashboard'/>
        </Route>
        <Route path='/dashboard' component={HomePageContainer}/>
        <Route path='/weeklyTrend' component={WeeklyTrendPage}/>
        <Route path='/valveStatus' component={ValveStatusPage}/>
        <Route path='/shiftReport' component={ShiftReportPage}/>
        <Route path='/settings' component={SettingsPage}/>
        <Route path='/uploadStats' component={UploadStatsPage}/>
        <Route path='/signed-out' component={SignedOutPage}/>
        <Route path='/about' component={AboutPage}/>
    </Switch>
  )
}
