import moment from "moment/moment";
import axios from "../../Axios/axiosConfig";

export class TcLineService {
    baseUrl;

    constructor(serverUrl) {
        this.baseUrl = serverUrl;
    }

    async getProductAnomalies(productId, selectedProductionLineId) {
        return axios.get(`${this.baseUrl}/production-lines/tc/${selectedProductionLineId}/products/${productId}/alerts`);
    }

    async getAreaAnomalies(productId, areaId, selectedProductionLineId) {
        return axios.get(`${this.baseUrl}/production-lines/tc/${selectedProductionLineId}/products/${productId}/areas/${areaId}/alerts`);
    }

    async getTcLineData(startDate, endDate, selectedProductionLineId) {
        return axios.get(`${this.baseUrl}/production-lines/tc/${selectedProductionLineId}/products?from=${startDate}&to=${endDate}`);
    }

    async getProductDetails(productId, selectedProductionLineId) {
        return axios.get(`${this.baseUrl}/production-lines/tc/${selectedProductionLineId}/products/${productId}/areas`);
    }

    async getMetersFlowRateData(productId, selectedProductionLineId, startDate, endDate) {
        const newStartDate = moment(startDate).subtract(1, "hour").toISOString();
        const newEndDate = moment(endDate).add(1, "hour").toISOString();
        return axios.get(`${this.baseUrl}/production_line/${selectedProductionLineId}/flowDetails/between?fromDate=${newStartDate}&toDate=${newEndDate}&timeBucketResolution=15`);
    }

}


