import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Col, Container, Row} from 'react-bootstrap';
import {store} from '../../Store/store';
import axios, {handleError} from '../../Axios/axiosConfig'
import FlowrateChartGroup from "../../Components/FlowrateChartGroup/FlowrateChartGroup";
import {default as BootstrapSpinner} from "react-bootstrap/Spinner";
import {FaSync} from "react-icons/fa";
import {getProductionLineId} from "../../General/general";


export default function UploadStatsPage(props) {
    const globalState = useContext(store);
    const serverUrl = useRef(`${globalState.state.serverUrl}/${getProductionLineId(globalState)}`);

    const [selectedProductionLine, setSelectedProductionLine] = useState(getProductionLineId(globalState));
    const [isLoading, setIsLoading] = useState(false);
    const [dailyData, setDailyData] = useState([]);
    const [hourlyData, setHourlyData] = useState([]);
    const [minuteData, setMinuteData] = useState([]);

    const calculateDates = () => {
        let fromDateLastMonth = new Date();
        fromDateLastMonth.setMonth(fromDateLastMonth.getMonth() - 1);
        fromDateLastMonth = fromDateLastMonth.toISOString();
        let fromDateLastMonthLength = fromDateLastMonth.length;
        fromDateLastMonth = fromDateLastMonth.substr(0, fromDateLastMonthLength - 5);

        let fromDateLast24 = new Date();
        fromDateLast24.setDate(fromDateLast24.getDate() - 1);
        fromDateLast24 = fromDateLast24.toISOString();
        let fromDateLengthLast24 = fromDateLast24.length;
        fromDateLast24 = fromDateLast24.substr(0, fromDateLengthLast24 - 5);

        let toDate = new Date().toISOString();
        let toDateLength = toDate.length;
        toDate = toDate.substr(0, toDateLength - 5);

        return [fromDateLastMonth, fromDateLast24, toDate];
    }

    let dates = calculateDates();
    let fromDateLastMonth = dates[0], fromDateLast24 = dates[1], toDate = dates[2];

    /**
     * Retrieves the daily stats according to the selected production line
     */
    const getDailyData = () => {
        const url = `${globalState.state.serverUrl}/stats/chart/${selectedProductionLine}?fromDate=${fromDateLastMonth}&toDate=${toDate}&res=day`;
        axios.get(url).then(res => {
            let response = res?.data?.values;

            if (!response) {
                console.error(`Data from getDailyData is empty`);
                return;
            }

            setDailyData(response.map((record, index) => {
                return {
                    name: record.label,
                    data: record.timeSeriesData
                }
            }));

        }).catch(error => {
            handleError(error)
            setDailyData([]);
        })
    }

    /**
     * Retrieves the hourly stats according to the selected production line
     */
    const getHourlyData = () => {
        const url = `${globalState.state.serverUrl}/stats/chart/${selectedProductionLine}?fromDate=${fromDateLast24}&toDate=${toDate}&res=hour`;
        axios.get(url).then(res => {
            let response = res?.data?.values;

            if (!response) {
                console.error(`Data from getHourlyData is empty`);
                return;
            }

            setHourlyData(response.map((record, index) => {
                return {
                    name: record.label,
                    data: record.timeSeriesData
                }
            }))
        }).catch(error => {
            handleError(error)
            setHourlyData([]);
        })
    }

    /**
     * Retrieves the minutely stats according to the selected production line
     */
    const getMinutelyData = () => {
        const url = `${globalState.state.serverUrl}/stats/chart/${selectedProductionLine}?fromDate=${fromDateLast24}&toDate=${toDate}&res=min`;
        axios.get(url).then(res => {
            let response = res?.data?.values;

            if (!response) {
                console.error(`Data from getMinutelyData is empty`);
                return;
            }

            setMinuteData(response.map((record, index) => {
                return {
                    name: record.label,
                    data: record.timeSeriesData
                }
            }))
        }).catch(error => {
            handleError(error)
            setMinuteData([]);
        })
    }


    /**
     * Calls every necessary request to display all the relevant data
     */
    const getAllData = () => {
        try {
            setIsLoading(true);
            getDailyData();
            getHourlyData();
            getMinutelyData();
        } catch (error) {
            handleError(error);
        } finally {
            setIsLoading(false);
        }
    }

    // Load the data on component initial mount
    useEffect(() => {
        setSelectedProductionLine(getProductionLineId(globalState));
        getAllData();
    }, []);

    useEffect(() => {
        const productionLineId = getProductionLineId(globalState);
        setSelectedProductionLine(productionLineId);
        serverUrl.current = `${globalState.state.serverUrl}/${productionLineId}`;
        getAllData();

    }, [globalState.state.selectedProductionLine])


    return (
        <Container fluid>
            <Button className="refresh-button" variant="primary" disabled={isLoading} onClick={() => getAllData()}>
                {isLoading ? <BootstrapSpinner animation="border" size="sm"/> : <FaSync/>}
            </Button>

            {/* Daily graph */}
            <Row>
                <Col>
                    <h4>Daily</h4>
                    <FlowrateChartGroup showLegend charts={dailyData} showSpinnerOnLoad/>
                </Col>
            </Row>

            <br/>
            <br/>

            {/* Hourly graph */}
            <Row>
                <Col>
                    <h4>Hourly</h4>
                    <FlowrateChartGroup showLegend charts={hourlyData} showSpinnerOnLoad/>
                </Col>
            </Row>

            <br/>
            <br/>

            {/* Minute graph */}
            <Row>
                <Col>
                    <h4>Per Minute</h4>
                    <FlowrateChartGroup showLegend charts={minuteData} showSpinnerOnLoad/>
                </Col>
            </Row>
        </Container>
    );
}
