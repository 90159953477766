import React, {useContext, useEffect, useState} from 'react';
import {Button, Card, Col, Container, NavItem, Row, Spinner} from "react-bootstrap";
import DataTable from "../../Components/Beverages/StepReportTable/DataTable";
import {Comparator, numberFilter, textFilter} from "react-bootstrap-table2-filter";
import {FaCalendarAlt, RiArrowUpSLine,} from "react-icons/all";
import './BeveragesHomePage.css';
import Navbar from "react-bootstrap/Navbar";
import RangeIndicator from "../../Components/RangeIndicator/RangeIndicator";
import ProgressChart from "../../Components/ProgressChart/ProgressChart";
import Badge from "react-bootstrap/Badge";
import ObjectDetailsPage from "./ObjectDetailsPage";
import BeveragesHomePageFullTable from "./BeveragesHomePageFullTable";
import AlertsModal from "./AlertsModal";
import {BeveragesLineService} from "./BeveragesLineService";
import {CgInfinity} from "react-icons/cg";
import {
    cellFormatter,
    cellTimeFormatter,
    durationFormatter,
    getLastWeekEndDate,
    getLastWeekStartDate,
    getProductionLineId,
    removeFractionDigits
} from "../../General/general";
import {store} from "../../Store/store";
import moment from "moment/moment";
import BeveragesDateRangePicker from "./BeveragesDateRangePicker";
import {RiArrowDownSLine} from "react-icons/ri";
import Dropdown from "react-dropdown";
import ExportDropdown from "./ExportDropdown";
import {IoMdArrowDropdown, IoMdArrowDropup} from "react-icons/io";

export default function BeveragesHomePageNew() {
    const [moreDetailsButtonClicked, setMoreDetailsButtonClicked] = useState(false);
    const [dateApplied, setDateApplied] = useState(true);
    const [isDownloadButtonClicked, setIsDownloadButtonClicked] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [lineFilterValue, setLineFilterValue] = useState('');
    const [startTimeFilterValue, setStartTimeFilterValue] = useState('');
    const [shiftFilterValue, setShiftFilterValue] = useState('');
    const [objectGroupFilterValue, setObjectGroupFilterValue] = useState('');
    const [objectNameFilterValue, setObjectNameFilterValue] = useState('');
    const [recipeFilterValue, setRecipeFilterValue] = useState('');
    const [scoreFilterValue, setScoreFilterValue] = useState('');
    const [freshWaterVolumeFilterValue, setFreshWaterVolumeFilterValue] = useState('');
    const [alertsFilterValue, setAlertsFilterValue] = useState({number: 1, comparator: Comparator.GE});
    const [statusFilterValue, setStatusFilterValue] = useState('');
    const [durationFilterValue, setDurationFilterValue] = useState('');
    const [isDownloadFinished, setIsDownloadFinished] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [showAlertsModal, setShowAlertsModal] = useState(false);
    const [showTotalAlertsModal, setShowTotalAlertsModal] = useState(false);
    const [isLineRequestInProgress, setIsObjectRequestInProgress] = useState(false);
    const [isLineAlertsRequestInProgress, setIsLineAlertsRequestInProgress] = useState(false);
    const [isTotalAlertsRequestInProgress, setIsTotalAlertsRequestInProgress] = useState(false);
    const beveragesLineService = new BeveragesLineService(process.env.REACT_APP_API_URL);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState(data);
    const [originalData, setOriginalData] = useState([]);
    const globalState = useContext(store);
    const selectedProductionLineId = getProductionLineId(globalState);
    const [startDate, setStartDate] = useState(getLastWeekStartDate());
    const [endDate, setEndDate] = useState(getLastWeekEndDate());
    const [alertsData, setAlertsData] = useState([]);
    const [totalAlertsData, setTotalAlertsData] = useState([]);
    const [isProductLineChanged, setIsProductLineChanged] = useState(false);
    const [isFirstRequest, setIsFirstRequest] = useState(true);
    const [sortState, setSortState] = useState({});
    const [showTable, setShowTable] = useState(false);
    const [showKpi, setShowKpi] = useState(true);
    const [selectedShift, setSelectedShift] = useState('All Shifts');
    const [selectedStatus, setSelectedStatus] = useState('All Statuses');
    const [rowHeight, setRowHeight] = useState('185px');
    const statusOptions = [{label: 'All Statuses', value: 'All Statuses'}, {label: 'Active', value: 'true'}, {
        label: 'Done',
        value: 'false'
    }];
    const shiftOptions = [{label: 'All Shifts', value: 'All Shifts'}, {
        label: 'Morning',
        value: 'morning'
    }, {label: 'Day', value: 'day'}, {label: 'Night', value: 'night'}];

    const formatDate = (date) => {
        if (!date) {
            return '';
        }
        const formattedString = moment(date).format('ll HH:mm');
        return formattedString === 'Invalid date' ? '' : formattedString;
    }

    const getBeveragesObjectData = async (startDate, endDate, selectedProductionLineId) => {
        try {
            const utcStartDate = moment(startDate).utcOffset(0, true).toISOString();
            const utcEndDate = moment(endDate).utcOffset(0, true).toISOString();
            setData([]);
            setIsObjectRequestInProgress(true);
            setCurrentPage(1);
            const response = await beveragesLineService.getBeveragesObjectData(utcStartDate, utcEndDate, selectedProductionLineId);
            let data = response.data.map(obj => {
                return {
                    ...obj,
                    startTimeFormatted: formatDate(obj.startTime),
                    duration: removeFractionDigits(obj.duration / 60),
                    durationMinValue: removeFractionDigits(obj.durationMinValue / 60),
                    durationMaxValue: removeFractionDigits(obj.durationMaxValue / 60)
                };
            })
            setData(data);
            setFilteredData(data);
            setOriginalData(data);
            setIsObjectRequestInProgress(false);
        } catch (error) {
            setIsObjectRequestInProgress(false);
            console.error(error);
        }

    }

    const dateSorter = (a, b, order) => {
        return order === 'asc' ? Date.parse(a) - Date.parse(b) : Date.parse(b) - Date.parse(a);
    }

    const onSort = (field, order) => {
        setSortState({dataField: field, order: order})
    }
    const rowStyle = (row) => {
        if (row.isActive && row.alerts > 0) {
            return {backgroundColor: 'rgba(248,32,6,0.2)'};

        }
    }

    const durationRangeFormatter = (cell, row) => {
        if (((row.objectName === 'Break' || row.objectName === 'Down Time') || row.objectName === 'Communication Error') || (!row.durationMinValue || !row.durationMaxValue)) {
            return <div style={{opacity: 0.7}} className='p-3'>{cell}</div>
        }
        return <div className='pr-5 pl-5'><RangeIndicator minValue={row.durationMinValue} value={cell}
                                                          maxValue={row.durationMaxValue}/></div>
    }

    const freshWaterVolumeFormatter = (cell, row) => {
        if (!row.freshWaterVolumeMinValue || !row.freshWaterVolumeMaxValue) {
            return cell ? (cell / 1000).toFixed(2) : null;
        }
        return (row.objectName === 'Break' || row.objectName === 'Down Time' || row.objectName === 'Communication Error') ?
            <div style={{opacity: 0.7}} className='p-3'>{cell / 1000}</div> :
            <div className='pr-5 pl-5'><RangeIndicator
                minValue={row.freshWaterVolumeMinValue === 0 ? 0 : parseFloat((row.freshWaterVolumeMinValue / 1000).toFixed(2))}
                value={cell === 0 ? 0 : parseFloat((cell / 1000).toFixed(2))}
                maxValue={row.freshWaterVolumeMaxValue === 0 ? 0 : parseFloat((row.freshWaterVolumeMaxValue / 1000).toFixed(2))}/>
            </div>
    }

    const getColorByValue = (value) => {
        let color;
        if (value >= 85) {
            color = 'green';
        } else if (value < 85 && value >= 55) {
            color = 'yellow';
        } else {
            color = 'red';
        }
        return color;
    }

    const getHealthScoreCardClass = () => {
        let alertsFound = false;
        data.forEach(obj => {
            if (obj.alerts > 0 && obj.isProdInProgress) {
                alertsFound = true;
            }
        })
        if (!alertsFound) {
            return 'sub-green-card-style';
        } else {
            return 'sub-red-card-style';
        }
    }

    useEffect(() => {
        const updateHeight = () => {
            console.log(' window. innerHeight ', window.innerHeight)
            if (window.innerHeight < 650) {
                setRowHeight('400px');
            } else if (window.innerHeight <= 900) {
                setRowHeight('400px');
            } else {
                setRowHeight('185px');
            }
        };

        updateHeight();
        window.addEventListener('resize', updateHeight);

        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, []);

    useEffect(() => {
        let newFilteredData = [...originalData];
        if (selectedShift !== 'All Shifts') {
            newFilteredData = newFilteredData.filter(item => item.shift === selectedShift);
        }
        if (selectedStatus !== 'All Statuses') {
            newFilteredData = newFilteredData.filter(item => {
                const selectedStatusBoolValue = selectedStatus === 'true';
                return item.isActive === selectedStatusBoolValue
            });
        }
        setData(newFilteredData);
    }, [selectedShift, selectedStatus]);

    useEffect(() => {
        if (isDownloadFinished) {
            setIsDownloadButtonClicked(false);
        }
        setIsDownloadFinished(false);
    }, [isDownloadFinished])

    useEffect(() => {
        setIsFirstRequest(false);
        if ((dateApplied || isProductLineChanged)) {
            setData([]);
            setFilteredData([]);
            setDateApplied(false);
            setIsProductLineChanged(false);
            getBeveragesObjectData(startDate, endDate, selectedProductionLineId);
        }
    }, [dateApplied, isProductLineChanged])

    useEffect(() => {
        if (!isFirstRequest) {
            setIsProductLineChanged(true);
            setMoreDetailsButtonClicked(false);
        }
    }, [selectedProductionLineId])

    const scoreFormatter = (cell) => {
        let variant = getColorByValue(cell?.toFixed(0));
        return <div className='pr-3 pl-3'><ProgressChart percentage={cell} variant={variant} value={cell}/></div>
    }

    const handleMoreDetailsButtonClick = (row) => {
        setSelectedRow(row);
        setMoreDetailsButtonClicked(true);
    }

    const objectNameFormatter = (cell, row) => {
        return <div style={{textAlign: 'left'}}><Button onClick={() => handleMoreDetailsButtonClick(row)}
                                                        variant={"link"} style={{
            textAlign: 'left',
            textDecoration: 'underline'
        }}>{cell ? cell : row.id}</Button></div>
    }

    const healthFormatter = (cell) => {
        let variant = getColorByValue(cell?.toFixed(0));
        return <ProgressChart percentage={cell} variant={variant} value={cell}/>
    }

    const generalFormatter = (cell) => {
        return cell ? cell : '-';
    }

    const rinseWaterFormatter = (cell) => {
        return cell ? parseFloat((cell / 1000).toFixed(2)) : 0;
    }

    const alertsFormatter = (cell, row) => {
        if (cell > 0) {
            return <div className='justify-content-center align-items-center'>
                <div className={'d-inline-block'}>
                    {cell}
                </div>
                <div className={'d-inline-block'}>
                    <Button style={{height: '24.5px'}} className='pl-1 pr-0 pt-0 pb-0' onClick={() => {
                        getObjectAlerts(row.id, selectedProductionLineId);
                        setShowAlertsModal(true);
                    }} size={'sm'} variant={'link'}><h6><Badge pill variant={"danger"}> i</Badge></h6>
                    </Button>
                </div>
            </div>
        } else {
            return cell
        }
    }

    const lineFormatter = (cell, row) => {
        const parts = cell.split("_");

        let result = null;
        for (let i = 0; i < parts.length - 1; i++) {
            if (parts[i].includes("line")) {
                result = parts[i + 1];
                break;
            }
        }
        return result;
    }

    let pageSize = data.length > 8 ? 6 : 7;

    const preRinseFreshWaterFilter = textFilter({
        style: {width: 170, margin: '0 auto', height: 30, fontSize: 12}, // your custom styles on input
    });

    const startTimeFilter = textFilter({
        style: {width: 100, margin: '0 auto', height: 30, fontSize: 12}, defaultValue: startTimeFilterValue
    });

    const postRinseFreshWaterFilter = textFilter({
        style: {width: 180, margin: '0 auto', height: 30, fontSize: 12}, defaultValue: shiftFilterValue
    });

    const objectGroupFilter = textFilter({
        style: {margin: '0 auto', height: 30, fontSize: 12}, defaultValue: objectGroupFilterValue
    });

    const objectNameFilter = textFilter({
        style: {margin: '0 auto', height: 30, fontSize: 12}, defaultValue: objectNameFilterValue
    });

    const recipeFilter = textFilter({
        style: {margin: '0 auto', height: 30, fontSize: 12}, defaultValue: recipeFilterValue
    });

    const durationFilter = textFilter({
        style: {width: 250, margin: '0 auto', height: 30, fontSize: 12}, defaultValue: durationFilterValue
    });

    const freshWaterVolumeFilter = textFilter({
        style: {width: 250, margin: '0 auto', height: 30, fontSize: 12}, defaultValue: freshWaterVolumeFilterValue
    });

    const descriptionFilter = textFilter({
        style: {width: 300, margin: '0 auto'}
    });

    const alertsFilter = numberFilter({
        style: {width: 150, height: 30},

        margin: '0 auto',
        placeholder: ' ',
        numberStyle: {padding: 0, maxWidth: '80px', fontSize: 12, textAlign: 'center'},
        comparatorStyle: {
            width: '42px',
            marginRight: 5,
            padding: 0,
            fontSize: 12,
        },
        defaultValue: alertsFilterValue
    });

    const lineFilter = textFilter({
        style: {width: 100, margin: '0 auto', height: 30, fontSize: 12},
    });

    const headerStyle = {fontSize: 15, border: 'none'};
    const colStyle = {fontSize: 15, fontWeight: 'bold', verticalAlign: 'center'};
    const wideColStyle = {minWidth: '100px', fontSize: 15, fontWeight: 'bold', verticalAlign: 'center'};
    const columns = [
        {
            dataField: 'id',
            title: 'ID',
            hidden: true
        },
        {
            dataField: 'objectName',
            title: 'Object Name',
            formatter: objectNameFormatter,
            sort: true,
            onSort: onSort,
            filter: objectNameFilter
        },
        {
            dataField: 'line',
            title: 'Line',
            text: 'line',
            sort: true,
            formatter: lineFormatter,
            onSort: onSort,
            filter: lineFilter
        },
        {
            dataField: 'recipe',
            title: 'Recipe',
            sort: true,
            onSort: onSort,
            formatter: generalFormatter,
            filter: recipeFilter
        },
        {
            dataField: 'startTimeFormatted',
            title: 'Start Time',
            filter: startTimeFilter,
            sortFunc: dateSorter,
            sort: true,
            onSort: onSort
        },
        {
            dataField: 'preRinseFreshWaterL',
            title: 'Pre Rinse Water [\u33A5]',
            formatter: rinseWaterFormatter,
            text: 'Pre Rinse Water [\u33A5]',
            sort: true,
            onSort: onSort,
            filter: preRinseFreshWaterFilter
        },
        {
            dataField: 'postRinseFreshWaterL',
            title: 'Post Rinse Water [\u33A5]',
            formatter: rinseWaterFormatter,
            sort: true,
            onSort: onSort,
            filter: postRinseFreshWaterFilter,
        },
        {
            dataField: 'objectGroup',
            title: 'Object Group',
            formatter: generalFormatter,
            sort: true,
            onSort: onSort,
            filter: objectGroupFilter
        },
        {
            dataField: 'freshWaterVolume',
            title: 'Fresh Water Volume [\u33A5]',
            formatter: freshWaterVolumeFormatter,
            filter: freshWaterVolumeFilter,
            sort: true,
            onSort: onSort
        },
        {
            dataField: 'duration',
            title: 'Duration [Minutes]',
            formatter: durationRangeFormatter,
            filter: durationFilter,
            sort: true,
            onSort: onSort
        },
        {
            dataField: 'alerts',
            title: '# Alerts',
            formatter: alertsFormatter,
            sort: true,
            onSort: onSort,
            filter: alertsFilter
        },

    ];

    const alertsColumns =
        [
            {dataField: 'id', title: 'ID', hidden: true},
            {dataField: 'startTime', title: 'Start Time', sort: true, formatter: cellTimeFormatter},
            {dataField: 'endTime', title: 'End Time', sort: true, formatter: cellTimeFormatter},
            {dataField: 'type', title: 'Type', sort: true, formatter: cellFormatter},
            {dataField: 'message', title: 'Description', sort: true, formatter: cellFormatter},
        ];

    const totalAlertsColumns =
        [
            {dataField: 'id', title: 'ID', hidden: true},
            {
                dataField: 'startTime',
                title: 'Start Time',
                sort: true,
                formatter: cellTimeFormatter,
                filter: textFilter()
            },
            {dataField: 'endTime', title: 'End Time', sort: true, formatter: cellTimeFormatter, filter: textFilter()},
            {dataField: 'objectName', title: 'Object Name', sort: true, formatter: cellFormatter, filter: textFilter()},
            {dataField: 'recipeName', title: 'Recipe Name', sort: true, formatter: cellFormatter, filter: textFilter()},
            {
                dataField: 'stepGroupName',
                title: 'Step Group',
                sort: true,
                formatter: cellFormatter,
                filter: textFilter()
            },
            {
                dataField: 'type',
                title: 'Type',
                sort: true,
                formatter: cellFormatter,
                filter: textFilter()
            },
            {
                dataField: 'message',
                title: 'Description',
                sort: true,
                formatter: cellFormatter,
                filter: descriptionFilter
            },
        ];


    const toggleTableVisibility = () => {
        setShowTable(!showTable);
    };

    const toggleKpiVisibility = () => {
        setShowKpi(!showKpi);
    };

    const calculateRecuperatedWater = (isTarget) => {
        let totalRecuperatedWater = 0;
        let totalFreshWaterUsed = 0;
        originalData.forEach(obj => {
            totalRecuperatedWater += isTarget ? obj.volumeRecuperatedLTarget : obj.recuperatedVolume;
            totalFreshWaterUsed += obj.kpi1;
        })
        if (totalRecuperatedWater === 0 && totalFreshWaterUsed === 0) {
            return 0;
        }
        return removeFractionDigits((totalRecuperatedWater / (totalFreshWaterUsed)) * 100);
    }

    const getFreshWaterUsed = (isTarget) => {
        let totalValue = 0;
        originalData.forEach(obj => {
            totalValue += isTarget ? obj.volumeFreshWaterLTarget : obj.kpi1;
        })
        return removeFractionDigits(totalValue / 1000);
    }

    const getFreshwaterUsedPreRinseStep = (isTarget) => {
        let totalValue = 0;
        originalData.forEach(obj => {
            totalValue += isTarget ? obj.preRinseFreshWaterLTarget : obj.preRinseFreshWaterL;
        })

        return removeFractionDigits(totalValue / 1000);
    }
    const getFreshwaterDrainedPostRinseStep = (isTarget) => {
        let totalValue = 0;
        originalData.forEach(obj => {
            totalValue += isTarget ? obj.postRinseFreshWaterLTarget : obj.postRinseFreshWaterL;
        })
        return removeFractionDigits(totalValue / 1000);
    }
    const getFreshwaterDrainedOtherSteps = (isTarget) => {
        let totalValue = 0;
        originalData.forEach(obj => {
            totalValue += isTarget ? obj.othersFreshWaterLTarget : obj.othersFreshWaterL;
        })
        return removeFractionDigits(totalValue / 1000);
    }

    const getNumberOfCIP = () => {
        let count = 0;
        originalData.forEach(obj => {
            if (obj.objectName !== 'Down Time' && obj.objectName !== 'Break' && obj.objectName !== 'Communication Error') {
                count++;
            }
        })
        return count;
    }

    const getTotalAlerts = () => {
        let totalAlerts = 0;
        originalData.forEach(obj => {
            totalAlerts += obj.alerts;
        })
        if (totalAlerts === 0) {
            return 0;
        } else {
            return totalAlerts.toLocaleString();
        }

    }

    const getAlertNumber = () => {
        let alertCount = 0;
        originalData.forEach(obj => {
            if (obj.alerts > 0 && obj.isProdInProgress) {
                alertCount += 1;
            }
        })
        if (alertCount === 0) {
            return <h5><Badge className={'pl-5 pr-5'} pill variant={'info'}>No Open Alerts</Badge></h5>
        } else if (alertCount === 1) {
            return <h5><Badge className={'pl-5 pr-5'} pill variant={'info'}>{`${alertCount} Open Alert`}</Badge></h5>
        } else {
            return <h5><Badge className={'pl-5 pr-5'} pill variant={'info'}>{`${alertCount} Open Alerts`}</Badge></h5>
        }
    }

    const getObjectAlerts = async (objectId, selectedProductionLineId) => {
        try {
            setAlertsData([]);
            setIsLineAlertsRequestInProgress(true);
            const response = await beveragesLineService.getObjectAlerts(objectId, selectedProductionLineId);
            setIsLineAlertsRequestInProgress(false);
            setAlertsData(response.data);
        } catch (error) {
            setIsLineAlertsRequestInProgress(false);
            console.error(error);
        }
    }

    const getTotalAlertsData = async () => {
        try {
            setTotalAlertsData([]);
            setIsTotalAlertsRequestInProgress(true);
            const utcStartDate = moment(startDate).utcOffset(0, true).toISOString();
            const utcEndDate = moment(endDate).add(1, 'days').utcOffset(0, true).toISOString();
            const response = await beveragesLineService.getTotalAlerts(utcStartDate, utcEndDate, selectedProductionLineId,);
            setIsTotalAlertsRequestInProgress(false);
            setTotalAlertsData(response.data);
        } catch (error) {
            setIsTotalAlertsRequestInProgress(false);
            console.error(error);
        }
    }

    const getDiffFromTarget = (value, target) => {
        value = value.toString().replace(/,/g, '');
        value = parseInt(value, 10);
        target = target.toString().replace(/,/g, '');
        target = parseInt(target, 10);
        const diff = value - target;
        return <>
            <div style={{display: "inline-block"}}>
                <div style={{display: "inline-block"}}> {
                    diff >= 0 ?
                        <h6 style={{display: 'inline-block', color: '#ff0000'}}>+{diff}</h6> :
                        <h6 style={{
                            display: 'inline-block',
                            color: '#00b050',
                        }}>{`-${diff * -1}`}</h6>}
                    <div style={{display: 'inline-block', color: diff >= 0 ? '#ff0000' : '#00b050'}}>&#13221;</div>
                    <h6 className='ml-1' style={{
                        display: 'inline-block',
                        color: diff >= 0 ? '#ff0000' : '#00b050'
                    }}>({
                        target === 0 ? value === 0 ? 0 : <CgInfinity/> : Math.round(((value - target) / target) * 100)
                    } %)</h6>
                </div>
                <div>
                    <h6>
                        Target: {target}&#13221;
                    </h6>
                </div>
            </div>
            <div style={{display: "inline-block"}}>
                {diff >= 0 ? <IoMdArrowDropup style={{transform: 'translateY(-20px)', color: '#ff0000'}} size={50}/> :
                    <IoMdArrowDropdown style={{transform: 'translateY(-20px)', color: '#00b050'}} size={50}/>}
            </div>
        </>
    }

    const getDiffPercentageFromTarget = () => {
        const percentageRecuperated = calculateRecuperatedWater(false);
        const targetPercentageRecuperated = calculateRecuperatedWater(true);
        const diff = percentageRecuperated - targetPercentageRecuperated;
        return <>
            <div style={{display: "inline-block"}}>
                <div style={{display: "inline-block"}}>
                    <h6 className='ml-1' style={{
                        display: 'inline-block',
                        color: diff >= 0 ? '#00b050' : '#ff0000'
                    }}>{diff} %</h6>
                </div>
                <div><h6>
                    Target: {targetPercentageRecuperated}%
                </h6>
                </div>
            </div>
            <div style={{display: "inline-block"}}>
                {diff >= 0 ? <IoMdArrowDropup style={{transform: 'translateY(-20px)', color: '#00b050'}} size={50}/> :
                    <IoMdArrowDropdown style={{transform: 'translateY(-20px)', color: '#ff0000'}} size={50}/>}
            </div>
        </>
    }

    const getDiffDurationFromTarget = (value, target) => {
        const diff = value - target;
        const durationDiff = formatDuration(diff);
        return <>
            <div style={{display: "inline-block"}}>
                <div style={{display: "inline-block"}}> {
                    diff >= 0 ?
                        <h7 style={{
                            display: 'inline-block',
                            color: '#ff0000',
                        }}>{durationDiff}</h7> :
                        <h7 style={{
                            display: 'inline-block',
                            color: '#00b050',
                        }}>{`${durationDiff}`}</h7>}
                    <h7 className='ml-1' style={{
                        display: 'inline-block',
                        color: diff >= 0 ? '#ff0000' : '#00b050'
                    }}>({
                        Math.round(((value - target) / target) * 100)
                    } %)
                    </h7>
                </div>
                <div>
                    <h7>Target: {formatDuration(target)}</h7>
                </div>
            </div>
            <div style={{display: "inline-block"}}>
                {diff >= 0 ? <IoMdArrowDropup style={{transform: 'translateY(-20px)', color: '#ff0000'}} size={50}/> :
                    <IoMdArrowDropdown style={{transform: 'translateY(-20px)', color: '#00b050'}} size={50}/>}
            </div>
        </>
    }

    function formatDuration(minutesInput) {
        const isNegative = minutesInput < 0;
        let minutes = Math.abs(minutesInput).toFixed(0);
        const hours = Math.floor(minutes / 60);
        minutes %= 60;

        let result = `${hours}h ${minutes}m`;
        result = result.trim();

        return isNegative ? `-${result}` : result;
    }

    const calculateTotalDuration = (isTarget) => {
        let duration = 0;
        originalData.forEach(obj => {
            duration += isTarget ? obj.durationSTarget : obj.durationSTarget > 0 ? obj.duration : 0;
        })
        return duration


    }

    const renderWidgets = () => {
        return (
            <Container fluid>
                <Row style={{height: rowHeight}} className="justify-content-center row-custom">
                    <Col md={4} style={{minWidth: '200px', maxWidth: '400px'}} className={'pl-2 pr-2 pb-3'}>
                        <Card className='ml-3' bsPrefix={'sub-card-style'} bg={'white'}>
                            <Card.Body className='p-0 pt-4'>
                                <Card.Subtitle bsPrefix={'card-text-info'}><h4>Alerts</h4></Card.Subtitle>
                                <div style={{display: 'inline-block'}} className={'mr-1'}>
                                    <Card.Text className='mt-2'><Button onClick={async () => {
                                        await getTotalAlertsData();
                                        setShowTotalAlertsModal(true);
                                    }} style={{color: 'black'}} variant='link'><h4>{getTotalAlerts()}</h4>
                                    </Button></Card.Text>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} style={{minWidth: '200px', maxWidth: '400px'}} className={'pl-2 pr-2 pb-3'}>
                        <Card bsPrefix={'sub-card-style'} bg={'white'}>
                            <Card.Body className='p-0 pt-4'>
                                <Card.Subtitle bsPrefix={'card-text-info'}><h4># CIP </h4></Card.Subtitle>
                                <Card.Text className='mt-4'><h4>{getNumberOfCIP()}</h4></Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} style={{minWidth: '200px', maxWidth: '400px'}} className={'pl-2 pr-2 pb-3'}>
                        <Card bsPrefix={'sub-card-style'} bg={'white'}>
                            <Card.Body className='p-0 pt-4'>
                                <Card.Subtitle bsPrefix='card-text-info'><h5 className={'mb-0'}>Fresh Water Used </h5>
                                </Card.Subtitle>
                                <div style={{display: 'inline-block', transform: 'translateY(-10px)'}}>
                                    <div style={{display: 'inline-block'}} className={'mr-1'}>
                                        <Card.Text as='h5'>{getFreshWaterUsed(false).toLocaleString()}</Card.Text>
                                    </div>
                                    <div style={{display: 'inline-block'}} className={'mt-3'}>
                                        <Card.Text as='h5'>&#13221;</Card.Text>
                                    </div>
                                </div>
                                <div className='ml-2' style={{
                                    display: 'inline-block',
                                    borderLeft: '1px solid',
                                    paddingBottom: '60px',
                                    transform: 'translateY(13px)'
                                }}/>
                                <div style={{display: 'inline-block'}} className={'ml-2'}>
                                    {getDiffFromTarget(getFreshWaterUsed(false).toLocaleString(), getFreshWaterUsed(true).toLocaleString())}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} style={{minWidth: '200px', maxWidth: '400px'}} className={'pl-2 pr-2 pb-3'}>
                        <Card bsPrefix={'sub-card-style'} bg={'white'}>
                            <Card.Body className='p-0 pt-2'>
                                <Card.Subtitle bsPrefix={'card-text-info'}><h5>Fresh Water Used</h5></Card.Subtitle>
                                <Card.Subtitle><h5> Pre Rinse Steps </h5></Card.Subtitle>
                                <div style={{display: 'inline-block', transform: 'translateY(-10px)'}}>
                                    <div style={{display: 'inline-block'}} className={'mr-1'}>
                                        <Card.Text
                                            as='h5'>{getFreshwaterUsedPreRinseStep(false).toLocaleString()}</Card.Text>
                                    </div>
                                    <div style={{display: 'inline-block'}} className={'mt-3'}>
                                        <Card.Text>&#13221;</Card.Text>
                                    </div>
                                </div>
                                <div className='ml-2' style={{
                                    display: 'inline-block',
                                    borderLeft: '1px solid',
                                    paddingBottom: '60px',
                                    transform: 'translateY(13px)'
                                }}/>
                                <div style={{display: 'inline-block'}} className={'ml-2'}>
                                    {getDiffFromTarget(getFreshwaterUsedPreRinseStep(false), getFreshwaterUsedPreRinseStep(true))}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row style={{height: rowHeight}} className="justify-content-center row-custom">
                    <Col sm={2} md={4} style={{minWidth: '200px', maxWidth: '400px'}} className={'pl-2 pr-2 pb-3'}>
                        <Card className='ml-3' bsPrefix='sub-card-style' bg={'white'}>
                            <Card.Body className='p-0 pt-2'>
                                <Card.Subtitle bsPrefix={'card-text-info'}><h5>Fresh water Drained</h5></Card.Subtitle>
                                <Card.Subtitle><h5>Post Rinse Step</h5></Card.Subtitle>
                                <div style={{display: 'inline-block', transform: 'translateY(-10px)'}}>
                                    <div style={{display: 'inline-block'}} className={'mr-1'}>
                                        <Card.Text
                                            as='h5'>{getFreshwaterDrainedPostRinseStep(false).toLocaleString()}</Card.Text>
                                    </div>
                                    <div style={{display: 'inline-block'}} className={'mt-3'}>
                                        <Card.Text as='h5'>&#13221;</Card.Text>
                                    </div>
                                </div>
                                <div className='ml-2' style={{
                                    display: 'inline-block',
                                    borderLeft: '1px solid',
                                    paddingBottom: '60px',
                                    transform: 'translateY(13px)'
                                }}/>
                                <div style={{display: 'inline-block'}} className={'ml-2'}>
                                    {getDiffFromTarget(getFreshwaterDrainedPostRinseStep(false), getFreshwaterDrainedPostRinseStep(true))}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={2} md={4} style={{minWidth: '200px', maxWidth: '400px'}} className={'pl-2 pr-2 pb-3'}>
                        <Card bsPrefix={'sub-card-style'} bg={'white'}>
                            <Card.Body className='p-0 pt-2'>
                                <Card.Subtitle bsPrefix={'card-text-info'}><h5>Fresh water drained</h5></Card.Subtitle>
                                <Card.Subtitle><h5>Other Steps</h5></Card.Subtitle>
                                <div style={{display: 'inline-block', transform: 'translateY(-10px)'}}>
                                    <div style={{display: 'inline-block'}} className={'mr-1'}>
                                        <Card.Text
                                            as='h5'>{getFreshwaterDrainedOtherSteps(false).toLocaleString()}</Card.Text>
                                    </div>
                                    <div style={{display: 'inline-block'}} className={'mt-3'}>
                                        <Card.Text as='h5'>&#13221;</Card.Text>
                                    </div>
                                </div>
                                <div className='ml-2' style={{
                                    display: 'inline-block',
                                    borderLeft: '1px solid',
                                    paddingBottom: '60px',
                                    transform: 'translateY(13px)'
                                }}/>
                                <div style={{display: 'inline-block'}} className={'ml-2'}>
                                    {getDiffFromTarget(getFreshwaterDrainedOtherSteps(false), getFreshwaterDrainedOtherSteps(true))}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={2} md={4} style={{paddingLeft: 0, minWidth: '200px', maxWidth: '400px'}}
                         className={'pl-2 pr-2 pb-3'}>
                        <Card bsPrefix={'sub-card-style'} bg={'white'}>
                            <Card.Body>
                                <Card.Subtitle bsPrefix={'card-text-info'}><h5>Percentage</h5></Card.Subtitle>
                                <Card.Subtitle className='mb-1'><h5>Recuperated</h5>
                                </Card.Subtitle>
                                <div style={{display: 'inline-block', transform: 'translateY(-10px)'}}>
                                    <div style={{display: 'inline-block'}} className={'mr-1'}>
                                        <Card.Text as={'h5'}>{calculateRecuperatedWater(false)}</Card.Text>
                                    </div>
                                    <div style={{display: 'inline-block'}}>
                                        <Card.Text as='h5'>%</Card.Text>
                                    </div>
                                </div>
                                <div className='ml-2' style={{
                                    display: 'inline-block',
                                    borderLeft: '1px solid',
                                    paddingBottom: '60px',
                                    transform: 'translateY(13px)'
                                }}/>
                                <div style={{display: 'inline-block'}} className={'ml-2'}>
                                    {getDiffPercentageFromTarget()}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={2} md={4} style={{paddingLeft: 0, minWidth: '200px', maxWidth: '400px'}}
                         className={'pl-2 pr-2 pb-3'}>
                        <Card bsPrefix={'sub-card-style'} bg={'white'}>
                            <Card.Body>
                                <Card.Subtitle bsPrefix={'card-text-info'}><h5>Duration</h5></Card.Subtitle>
                                <div style={{display: 'inline-block', transform: 'translateY(-10px)'}}>
                                    <div style={{display: 'inline-block'}} className={'mr-1'}>
                                        <Card.Text as='h5'>{formatDuration(calculateTotalDuration(false))}</Card.Text>
                                    </div>
                                </div>
                                <div className='ml-2' style={{
                                    display: 'inline-block',
                                    borderLeft: '1px solid',
                                    paddingBottom: '60px',
                                    transform: 'translateY(13px)'
                                }}/>
                                <div style={{display: 'inline-block'}} className={'ml-2'}>
                                    {getDiffDurationFromTarget(calculateTotalDuration(false), calculateTotalDuration(true) / 60)}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>)
    }

    if (moreDetailsButtonClicked) {
        return <ObjectDetailsPage beveragesLineService={beveragesLineService}
                                  scoreFormatter={scoreFormatter}
                                  durationFormatter={durationFormatter}
                                  healthFormatter={healthFormatter}
                                  selectedRow={selectedRow}
                                  setMoreDetailsButtonClicked={setMoreDetailsButtonClicked}
                                  totalEfficiencyFormatter={freshWaterVolumeFormatter}
                                  selectedProductionLineId={selectedProductionLineId}
                                  alertsColumns={alertsColumns}

        />
    } else if (isDownloadButtonClicked) {
        return <BeveragesHomePageFullTable data={filteredData}
                                           columns={columns}
                                           setIsDownloadFinished={setIsDownloadFinished}
                                           lineFilterValue={lineFilterValue}
                                           startTimeFilterValue={startTimeFilterValue}
                                           shiftFilterValue={shiftFilterValue}
                                           objectGroupFilterValue={objectGroupFilterValue}
                                           objectNameFilterValue={objectNameFilterValue}
                                           recipeFilterValue={recipeFilterValue}
                                           scoreFilterValue={scoreFilterValue}
                                           freshWaterVolumeFilterValue={freshWaterVolumeFilterValue}
                                           statusFilterValue={statusFilterValue}
                                           alertsFilterValue={alertsFilterValue}
                                           durationFilterValue={durationFilterValue}
                                           startDate={startDate}
                                           endDate={endDate}
                                           renderWidgets={renderWidgets}
        />
    } else {
        return (
            <div>
                <Card style={{backgroundColor: isLineRequestInProgress ? 'white' : '#f5f7fb'}}
                      bsPrefix={'main-card-style'}>
                    <AlertsModal isAlertsRequestInProgress={isLineAlertsRequestInProgress} alertsData={alertsData}
                                 show={showAlertsModal} setShow={setShowAlertsModal} alertsColumns={alertsColumns}/>
                    <AlertsModal isAlertsRequestInProgress={isTotalAlertsRequestInProgress} alertsData={totalAlertsData}
                                 show={showTotalAlertsModal} setShow={setShowTotalAlertsModal}
                                 alertsColumns={totalAlertsColumns}/>
                    <Container fluid>
                        <Col>
                            <Row>
                                <Col>
                                    <Navbar>
                                        <Container fluid>
                                            <Navbar.Collapse>
                                                <NavItem>
                                                    <FaCalendarAlt size={30} className={'mr-3'}/>
                                                </NavItem>
                                                <NavItem style={{width: '20%'}}>
                                                    <BeveragesDateRangePicker startDate={startDate}
                                                                              endDate={endDate}
                                                                              setStartDate={setStartDate}
                                                                              setDateApplied={setDateApplied}
                                                                              setEndDate={setEndDate}
                                                    />
                                                </NavItem>
                                                <NavItem className='ml-4'>
                                                    <ExportDropdown beveragesLineService={beveragesLineService}
                                                                    setIsDownloadButtonClicked={setIsDownloadButtonClicked}
                                                                    startDate={startDate}
                                                                    endDate={endDate}
                                                                    selectedProductionLineId={selectedProductionLineId}
                                                    />
                                                </NavItem>
                                            </Navbar.Collapse>

                                        </Container>
                                    </Navbar>

                                </Col>
                            </Row>
                        </Col>
                    </Container>
                    <Row>
                        <Col md={1}>
                            <Button variant="link" onClick={toggleKpiVisibility}
                                    style={{textAlign: 'left', whiteSpace: 'nowrap'}}>
                                {showKpi ? "Hide KPI " : 'Show KPI'}
                                {showKpi ? <RiArrowUpSLine/> : <RiArrowDownSLine/>}
                            </Button>
                        </Col>
                    </Row>
                    <br/>
                    {isLineRequestInProgress ? <Spinner animation="border" variant="primary"/> : <>
                        {showKpi && renderWidgets()}
                        <Row className={'pt-4'}>
                            <Col md={1}>
                                <Button variant="link" onClick={toggleTableVisibility}
                                        style={{textAlign: 'left', whiteSpace: 'nowrap'}}>
                                    {showTable ? "Hide Table " : 'Show Table'}
                                    {showTable ? <RiArrowUpSLine/> : <RiArrowDownSLine/>}
                                </Button>
                            </Col>
                            {showTable &&
                                <Col md={1} className={'mr-3'}>
                                    <div style={{width: '160px'}}>
                                        <Dropdown className='custom-dropdown'
                                                  options={statusOptions}
                                                  onChange={(e) => {
                                                      setSelectedStatus(e.value)
                                                  }}
                                                  value={selectedStatus}
                                        />
                                    </div>
                                </Col>
                            }
                            {showTable &&
                                <Col md={1}>
                                    <Dropdown className='custom-dropdown'
                                              options={shiftOptions}
                                              onChange={(e) => {
                                                  setSelectedShift(e.value)
                                              }}
                                              value={selectedShift}
                                    />
                                </Col>
                            }

                        </Row>
                        {showTable && (<Row className={'pt-3'}>
                            <Col style={{overflowX: 'auto'}}>
                                <DataTable
                                    rowStyle={rowStyle}
                                    headerStyle={headerStyle}
                                    colStyle={colStyle}
                                    wideColStyle={wideColStyle}
                                    data={data}
                                    columns={columns}
                                    title={''}
                                    defaultSort={sortState}
                                    setLineFilterValue={setLineFilterValue}
                                    setStartTimeFilterValue={setStartTimeFilterValue}
                                    setObjectGroupFilterValue={setObjectGroupFilterValue}
                                    setShiftFilterValue={setShiftFilterValue}
                                    setObjectNameFilterValue={setObjectNameFilterValue}
                                    setRecipeFilterValue={setRecipeFilterValue}
                                    setDurationFilterValue={setDurationFilterValue}
                                    setScoreFilterValue={setScoreFilterValue}
                                    setFreshWaterVolumeFilterValue={setFreshWaterVolumeFilterValue}
                                    setAlertsFilterValue={setAlertsFilterValue}
                                    setStatusFilterValue={setStatusFilterValue}
                                    pageSize={pageSize}
                                    setCurrentPage={setCurrentPage}
                                    currentPage={currentPage}
                                    setFilteredData={setFilteredData}
                                    paginationSize={10}/>
                            </Col>
                        </Row>)}
                    </>}

                </Card>
            </div>
        )
    }
}
