import React, {createRef, useEffect, useRef, useState} from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import {Spinner} from 'react-bootstrap';
import moment from 'moment';
import {sensorChartColors} from '../../General/general';

/*
    props:
        showSpinnerOnLoad (Boolean): While props.charts is empty, displays a Spinner
        yAxisTitle (String): Text to display on the yAxis
        unitSymbol (String): Measurement Unit to add to the chart's tooltip
        tooltipFormatter (Function): Formats the tooltip of the chart's series
        charts (Array):
            - name: Chart name
            - data: Series data
            - color: Series color
 */
export default function FlowrateChartGroup(props) {
    const chartRef = useRef(null);
    const [options, setOptions] = useState({});

    const getXAxis = () => {
        if (props.verticalLineTimestamps) {
            let plotLinesArr;
            plotLinesArr = props.verticalLineTimestamps.map((verticalLineTimestamp) => {
                return {
                    color: 'black',
                    width: 3,
                    value: verticalLineTimestamp,
                    zIndex: 5
                }
            })
            return {plotLines: plotLinesArr}
        } else {
            return {}
        }
    }

    useEffect(() => {
        let options = {

            chart: {
                type: 'line', zoomType: 'x', marginRight: 90
            },
            title: {
                style: {fontSize: 20, fontWeight: 600, fontFamily: 'Poppins'}
            },
            xAxis: getXAxis(),
            yAxis: props.multipleYAxis ? [{
                lineWidth: 1, opposite: false, title: {
                    text: `${props.charts[0]?.name} (${props.charts[0]?.unitSymbol})`
                }
            }, {
                lineWidth: 1, title: {
                    text: `${props.charts[1]?.name} (${props.charts[1]?.unitSymbol})`
                },
            }] : {
                visible: !props.hideYAxis, title: {
                    text: (props.yAxisTitle) ? `${props.yAxisTitle} (${props.unitSymbol || ''})` : ` `
                }, plotLines: props.plotLines
            }, plotOptions: {
                series: {
                    threshold: props?.plotLines?.value ? props.plotLines.value : 0,
                    marker: {
                        enabled: true,
                        radius: 0,
                    }
                }
            }, tooltip: {
                formatter: function () {
                    if (props.tooltipFormatter) return (props.tooltipFormatter(this));

                    let unitSymbolToUse = props.unitSymbol;
                    // if (props.multipleYAxis) {
                    //     unitSymbolToUse = this.points[0].series.legendItem.textStr === props.unitSymbol[0].name ?
                    //         props.unitSymbol[0].unitSymbol :
                    //         props.unitSymbol[1].unitSymbol
                    // }
                    return [`<b>${moment(this.x).utc().format('llll')}</b>`].concat(this.points ? this.points.map(function (point) {
                        let separatorArr;
                        let num = Number(point.y);
                        separatorArr = num.toString().split('.');
                        num = Number(separatorArr[0]).toLocaleString();
                        separatorArr = Number(point.y).toFixed(2).split('.');
                        const fractionDigits = separatorArr[1];
                        return `${point.series.name}: ${num + '.' + fractionDigits} ${unitSymbolToUse || ''}`;
                    }) : []);
                }, split: true
            }, legend: {
                enabled: props.showLegend
            }, credits: {
                enabled: false
            }, navigator: {
                enabled: true, series: {
                    fillOpacity: 0, lineWidth: 0
                }
            }, rangeSelector: {
                dropdown: 'always', buttons: [{
                    type: 'hour', count: 1, text: '1 Hour', title: 'View 1 Hour'
                }, {
                    type: 'hour', count: 3, text: '3 Hours', title: 'View 3 Hours'
                }, {
                    type: 'all', text: 'All', title: 'View All'
                }], inputEnabled: false
            }, series: props.multipleYAxis ? props.charts.map((chart, index) => {
                return {
                    type: chart.type || 'line',
                    name: chart.name,
                    data: chart.data,
                    step: props.steps,
                    color: chart.color || sensorChartColors[index % props.charts.length],
                    yAxis: index, // visible: index === 1
                }
            }) : props.charts.map((chart, index) => {
                return {
                    type: chart.type || 'line',
                    name: chart.name,
                    data: chart.data,
                    step: props.steps,
                    color: chart.color || sensorChartColors[index % props.charts.length]
                }
            })
        }

        if (props.chartHeight) {
            options.chart.height = props.chartHeight;
        }

        if (props.verticalScroll) {
            options.chart.scrollablePlotArea = {
                minHeight: props.minScrollHeight || 800, scrollPositionY: 0.3
            }
        }

        setOptions(options)
    }, [props.charts])
    return (<>
        {(props.showSpinnerOnLoad && (!props.charts || props.charts.length === 0)) &&
            <Spinner variant="primary" animation="border"/>}
        {props.charts.length > 0 && <HighchartsReact
            key={`flowrate-chart-group`}
            ref={chartRef}
            options={options}
            highcharts={Highcharts}
            constructorType={'stockChart'}
        />}
    </>)
}
