import React from 'react';
import moment from 'moment/moment';
import {Col, Row} from 'react-bootstrap';
import DatetimeRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

const BeveragesDateRangePicker = (props) => {
    const OFFSET_START_DATE_LIMIT = new Date(2020, 7, 1);

    const handleCallback = (start, dates) => {
        props.setStartDate(new Date(dates.startDate));
        props.setEndDate(new Date(dates.endDate));
        props.setDateApplied(true);
    };

    return (
        <Row>
            <Col md={props.hasExportCsv ? 10 : 12}>
                <DatetimeRangePicker
                    onApply={handleCallback}
                    initialSettings={{
                        locale: {format: 'll'},
                        timePicker: false,
                        maxDate: moment(),
                        maxSpan: props.title === 'leaks History' ? {'days': 182} : null,
                        minDate: moment(OFFSET_START_DATE_LIMIT),
                        startDate: props.startDate ? moment(props.startDate).startOf('day') : moment().startOf('day'),
                        endDate: props.endDate ? moment(props.endDate).startOf('day') : moment().startOf('day'),
                        setStartDate: props.setStartDate,
                        setEndDate: props.setEndDate,
                        setDateApplied: props.setDateApplied,
                        ranges: {
                            'Last 7 days': [moment().subtract(6, 'days').startOf('day'), moment().startOf('day')],
                            'Last 30 Days': [moment().subtract(29, 'days').startOf('day'), moment().startOf('day')],
                            'Last 60 Days': [moment().subtract(59, 'days').startOf('day'), moment().startOf('day')],
                        },
                        autoApply: true
                    }}>
                    <input type="text" className="form-control"
                           style={{textAlign: 'center', fontSize: props.fontSize}}/>
                </DatetimeRangePicker>
            </Col>
        </Row>
    );
};

export default BeveragesDateRangePicker;
