import React, {useContext, useEffect, useState} from 'react';
import {Tab, Nav} from 'react-bootstrap';
import WeeklyTrendGraph from "./WeeklyTrendGraph";
import './WeeklyTrend.css';
import {BeveragesLineService} from "../Beverages/BeveragesLineService";
import {getProductionLineId} from "../../General/general";
import {store} from "../../Store/store";
import {useHistory} from 'react-router-dom';

const WeeklyTrend = () => {
    const [activeTab, setActiveTab] = useState('1');
    const globalState = useContext(store);
    const beveragesLineService = new BeveragesLineService(process.env.REACT_APP_API_URL);
    const selectedProductionLineId = getProductionLineId(globalState);
    const [weeklyTrendData, setWeeklyTrendData] = useState({});
    const lineType = globalState.state.selectedProductionLine?.lineType ||
        JSON.parse(globalState.state.selectedProductionLine)?.lineType;
    const history = useHistory();

    const getWeeklyTrendData = async () => {
        try {
            const response = await beveragesLineService.getWeeklyTrends(selectedProductionLineId);
            setWeeklyTrendData(response.data);
        } catch (error) {
            setWeeklyTrendData({});
            console.error(error);
        }
    };

    useEffect(() => {
        if (lineType !== 'beverages') {
            history.push('/dashboard');
        } else {
            getWeeklyTrendData();
        }
    }, [lineType, selectedProductionLineId, history]);

    return (
        <div className="weekly-trend-container">
            <Tab.Container id="left-tabs-example" activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
                <div className="weekly-trend-wrapper">
                    <Nav variant="pills" className="flex-column tab-nav">
                        <Nav.Item>
                            <Nav.Link eventKey="1"><h4>Alerts</h4></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="2"><h4>CIP</h4></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="3"><h4>Fresh Water Used</h4></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="4"><h4>Fresh Water Drained Pre Rinse Steps</h4></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="5"><h4>Fresh water Drained Post Rinse Step</h4></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="6"><h4>Fresh Water Drained Other Steps</h4></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="7"><h4>Percentage Recuperated</h4></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="8"><h4>Duration</h4></Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <div className="weekly-trend-content">
                        <Tab.Content style={{width: '100%', height: '100%'}}>
                            <Tab.Pane eventKey="1">
                                <WeeklyTrendGraph kpi="alerts" data={weeklyTrendData} title="Alerts" unitSymbol='#' isTooltipUnitSymbol={false}/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="2">
                                <WeeklyTrendGraph kpi="cip" data={weeklyTrendData} title="CIP" unitSymbol='#' isTooltipUnitSymbol={false}/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="3">
                                <WeeklyTrendGraph kpi="freshWaterUsed" data={weeklyTrendData} title="Fresh Water Used"  unitSymbol='㎥' isTooltipUnitSymbol={true}/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="4">
                                <WeeklyTrendGraph kpi="preRinseStep" data={weeklyTrendData}
                                                  title="Fresh Water Drained Pre Rinse Steps"  unitSymbol='㎥' isTooltipUnitSymbol={true}/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="5">
                                <WeeklyTrendGraph kpi="postRinseStep" data={weeklyTrendData}
                                                  title="Fresh Water Drained Post Rinse Step" unitSymbol='㎥' isTooltipUnitSymbol={true}/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="6">
                                <WeeklyTrendGraph kpi="otherSteps" data={weeklyTrendData}
                                                  title="Fresh Water Drained Other Steps" unitSymbol='㎥' isTooltipUnitSymbol={true}/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="7">
                                <WeeklyTrendGraph kpi="percentageRecuperated" data={weeklyTrendData}
                                                  title="Percentage Recuperated" unitSymbol='%' isTooltipUnitSymbol={true}/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="8">
                                <WeeklyTrendGraph kpi="duration" data={weeklyTrendData} title="Duration"  unitSymbol='Hours' isTooltipUnitSymbol={true}/>
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </div>
            </Tab.Container>
        </div>
    );
};

export default WeeklyTrend;
