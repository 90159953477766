import React, {useContext, useEffect, useRef, useState} from 'react';
import StageTargetTable from "../../Components/StageTargetTable/StageTargetTable";
import {Container, Row, Col} from "react-bootstrap";
import axios, { handleError } from '../../Axios/axiosConfig'
import {store} from "../../Store/store";
import {getProductionLineId} from "../../General/general";

export default function SettingsPage() {
    const globalState = useContext(store);
    const serverUrl = useRef(`${globalState.state.serverUrl}/${getProductionLineId(globalState)}`);

    const [tableData, setTableData] = useState([]);

    const getAllData = async () => {
        await axios.get(`${serverUrl.current}/targetFlowRate`).then(response => {
            setTableData(response.data);
        }).catch(error => handleError(error))
    }

    useEffect(() => {
        serverUrl.current = `${globalState.state.serverUrl}/${getProductionLineId(globalState)}`;
        getAllData();
    }, [globalState.state.selectedProductionLine])

    useEffect(() => {
        getAllData();
    }, [])

    return (
        <Container fluid>
            <Row>
                <Col>
                    <StageTargetTable data={tableData} />
                </Col>
            </Row>
        </Container>
    )
}