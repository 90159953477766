import React, {useContext, useEffect} from 'react';
import "react-datepicker/dist/react-datepicker.css";
import {Redirect, Route, Switch} from "react-router-dom";
import {Container} from 'react-bootstrap'
import './HomePageContainer.css'
import {store} from '../../Store/store'
import PotatoChipsHomePage from "../PotatoChips/PotatoChipsHomePage";
import TortillaChipsHomePage from "../TortillaChips/TortillaChipsHomePage";
import BeveragesHomePage from "../Beverages/BeveragesHomePage.js";

export default function HomePageContainer() {
    const globalState = useContext(store);
    const lineType = globalState.state.selectedProductionLine?.lineType ||
        JSON.parse(globalState.state.selectedProductionLine)?.lineType;
    return (
        <Container fluid>
            {lineType ?
                <Switch>
                    <Route exact path='/'>
                        <Redirect to='/dashboard'/>
                    </Route>

                    {lineType === 'beverages' &&
                        <Route exact path='/dashboard'>
                            <BeveragesHomePage/>
                        </Route>
                    }
                    {lineType === 'pc' &&
                        <Route exact path='/dashboard'>
                            <PotatoChipsHomePage/>
                        </Route>
                    }
                    {lineType === 'tc' &&
                        <Route exact path='/dashboard'>
                            <TortillaChipsHomePage/>
                        </Route>
                    }
                </Switch> : <p>Loading...</p>}
        </Container>
    );
}
