export const ProjectColors = {
    DarkNavy: '#1d2041',
    Navy: '#2c335e',
    Purple: '#c842f5',
    DarkBlue: '#17008B',
    Blue: '#037ffc',
    LightBlue: '#78c9ff',
    Green: '#32cd32',
    LightGreen: '#7DDF64',
    Red: '#dc143c',
    Orange: '#f05d23',
    Yellow: '#F3B700',
    DarkYellow: '#999900',
    Gray: '#b8b8b8',
    DimGray: '#696969',
    Black: '#000000',
    White: '#FFFFFF'
}