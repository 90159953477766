import React, {useEffect, useRef, useState} from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import {Button, Spinner} from 'react-bootstrap';
import moment from 'moment';
import './SensorCharts.css';
import {sensorChartColors} from '../../General/general';

const SensorCharts = ({
                          sensorData,
                          verticalLineTimestamps,
                          yAxisTitle,
                          tooltipFormatter,
                          showSpinnerOnLoad,
                          isModal
                      }) => {
    const chartRefs = useRef([]);
    const [options, setOptions] = useState([]);

    const getXAxis = () => {
        if (verticalLineTimestamps) {
            return {
                plotLines: verticalLineTimestamps.map((timestamp) => ({
                    color: 'black',
                    width: 3,
                    value: timestamp,
                    zIndex: 5
                }))
            };
        } else {
            return {};
        }
    };

    const syncExtremes = (e) => {
        const thisChart = e.target.chart;
        if (e.trigger !== 'syncExtremes') {
            Highcharts.each(Highcharts.charts, function (chart) {
                if (chart && chart.options.isModal === isModal && chart !== thisChart) {
                    if (chart.xAxis[0].setExtremes) {
                        chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, {trigger: 'syncExtremes'});
                    }
                }
            });
        }
    };

    const syncTooltip = (e) => {
        const thisChart = e.target.series.chart;
        Highcharts.each(Highcharts.charts, function (chart) {
            if (chart && chart.options.isModal === isModal && chart !== thisChart) {
                const point = chart.series[0].searchPoint({
                    chartX: e.chartX,
                    chartY: e.chartY
                }, true);

                if (point) {
                    point.onMouseOver();
                    chart.tooltip.refresh(point);
                    chart.xAxis[0].drawCrosshair(e, point);
                }
            }
        });
    };

    useEffect(() => {
        const sortedSensorData = sensorData
            .sort((a, b) => {
                return parseInt(b.group) - parseInt(a.group);
            });

        const newOptions = sortedSensorData.map((dataSet, index) => {
            const seriesData = dataSet.group === "1"
                ? (() => {
                    let colorIndex = 0;

                    const valvesSeries = Object.keys(dataSet.valves).map((key) => {
                        const obj = dataSet.valves[key];
                        return {
                            name: obj.label,
                            data: obj.timeSeriesData,
                            color: obj.color || sensorChartColors[colorIndex++ % sensorChartColors.length],
                            visible: obj.isVisible,
                        };
                    });

                    const returnStepsSeries = Object.keys(dataSet.returnSteps).map((key) => {
                        const obj = dataSet.returnSteps[key];
                        return {
                            name: obj.label,
                            data: obj.timeSeriesData,
                            color: obj.color || sensorChartColors[colorIndex++ % sensorChartColors.length],
                            visible: obj.isVisible,
                        };
                    });

                    return [...valvesSeries, ...returnStepsSeries];  // Combine both arrays.
                })()
                : dataSet.sensors
                    .map((obj, sensorIndex) => ({
                        name: obj.label,
                        data: obj.timeSeriesData,
                        color: obj.color || sensorChartColors[sensorIndex % sensorChartColors.length],
                        unitSymbol: obj.unitSymbol,
                        visible: obj.isVisible
                    }));

            return {
                chart: {
                    type: 'line',
                    zoomType: 'x',
                    events: {
                        load() {
                            const currentChart = this;
                            currentChart.xAxis[0].update({
                                events: {
                                    setExtremes: syncExtremes
                                }
                            });
                        },
                        mouseMove: syncTooltip
                    }
                },
                xAxis: {
                    crosshair: true,
                    events: {
                        setExtremes: syncExtremes
                    },
                    ...getXAxis()
                },
                yAxis: {
                    opposite: false,
                    title: {
                        text: 'Units'
                    }
                },
                series: seriesData,
                plotLines: true,
                plotOptions: {
                    series: {
                        marker: {
                            enabled: true,
                            radius: 0,
                        }
                    }
                },
                tooltip: {
                    shared: true,
                    formatter: function () {
                        if (tooltipFormatter) return tooltipFormatter(this);
                        return  dataSet.group === "1" ? `<b>${moment(this.x).utc().format('llll')}</b><br>${this.points.map(point => `${point.series.name}: ${point.y}`).join('<br>')}` : `<b>${moment(this.x).utc().format('llll')}</b><br>${this.points.map(point => `${point.series.name}: ${point.y.toFixed(2)} ${point.series.options.unitSymbol}`).join('<br>')}`;
                    }
                },
                legend: {
                    enabled: true,
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'middle',
                    width: 400,
                    x: 60,
                    y: 0,
                    itemStyle: {
                        fontSize: '12px'
                    }
                },
                credits: {
                    enabled: false
                },
                navigator: {
                    enabled: false
                },
                rangeSelector: {
                    enabled: false
                },
                isModal: isModal
            };
        });

        setOptions(newOptions);
    }, [sensorData]);

    return (
        <>
            {showSpinnerOnLoad && (!sensorData || sensorData.length === 0) &&
                <Spinner variant="primary" animation="border"/>}
            <div id="chart-container" className="chart-container">
                {sensorData.length > 0 && options.map((opt, index) => (
                    <div className="chart-wrapper" key={index}>
                        <HighchartsReact
                            highcharts={Highcharts}
                            constructorType={'stockChart'}
                            options={opt}
                            ref={(el) => chartRefs.current[index] = el}
                        />
                    </div>
                ))}
            </div>
        </>
    );
};

export default SensorCharts;
