import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const WeeklyTrendGraph = ({kpi, data, title, unitSymbol, isTooltipUnitSymbol}) => {
    const dates = Object.keys(data);
    const actualData = dates.map(date => data[date][kpi]?.actualData);
    const targetData = dates.map(date => data[date][kpi]?.targetData);

    const options = {
        chart: {
            type: 'line',
            height: '60%',
            zoomType: 'x',
        },
        title: {
            text: `Weekly Trends for ${title}`,
            style: {
                fontSize: '30px',
                color: '#000000',
            }
        },
        tooltip: {
            shared: true,
            useHTML: true,
            formatter: function () {
                let tooltipContent = `<div style="font-weight: bold;">${this.x}</div>`;
                this.points.forEach(point => {
                    tooltipContent += `<div>${point.series.name}: ${point.y}${title === 'Duration' ? ` ${isTooltipUnitSymbol ? unitSymbol : ''}` : (isTooltipUnitSymbol ? unitSymbol : '')}</div>`;
                });
                return tooltipContent;
            }
        },
        xAxis: {
            categories: dates,
            title: {
                text: 'Week',
                style: {
                    fontSize: '20px',
                }
            },
            labels: {
                style: {
                    fontSize: '14px',
                },
            }
        },
        yAxis: {
            title: {
                text: `${title} (${unitSymbol})`,
                style: {
                    fontSize: '20px',
                }
            },
            labels: {
                style: {
                    fontSize: '14px',
                },
            }

        },
        legend: {
            itemStyle: {
                fontSize: '16px',
            },
            x: 35
        },
        series: [
            {
                name: 'Actual Data',
                data: actualData,
                color: '#7cb5ec',
                marker: {
                    enabled: true,
                    radius: 1,
                },
            },
            {
                name: 'Target Data',
                data: targetData,
                color: '#434348',
                marker: {
                    enabled: true,
                    radius: 1,
                },
            },
        ],
    };

    return (
        <div className="highcharts-container">
            <HighchartsReact highcharts={Highcharts} options={options}/>
        </div>
    );
};

export default WeeklyTrendGraph;
