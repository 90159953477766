import Cookies from "js-cookie";

/** Class to wrap values that have a known expiry date.
 *  Includes methods for encoding/decoding as Base64 and helper methods to store/read as a cookie.
 *  @author Alan Givati - WINT
 */
export class Expiring {
    /** Constructor
     * @param value Value to store.
     * @param expiry {Date} expiration date
     */
    constructor(value, expiry) {
        this.value = value;
        this.expiry = expiry;
    }

    static decode(encoded) {
        const decoded = JSON.parse(atob(encoded));
        return new Expiring(decoded.value, decoded.expiry);
    }

    /** Create an "Expiring" value to be used in a cookie settings object.
     * @param value The value to assign to the Expiring object.
     * @param lifetime {number} The number of seconds from Date.now() that the cookie will expire in.
     */
    static fromNow(value, lifetime) {
        return new Expiring(value, new Date(Date.now().valueOf() + (lifetime * 1000)))
    }

    static fromCookie(cookieName) {
        return Expiring.decode(Cookies.get(cookieName));
    }

    encode() {
        const obj = {...this};
        const json = JSON.stringify(obj);
        return Buffer.from(json).toString("base64");
    }

    storeAsCookie(cookieName, options) {
        Cookies.set(cookieName, this.encode(), {...options, expires: this.expiry});
    }

    /** Get number of seconds remaining before this Expiring object expires according to its expiry date.
     * @returns {number}
     */
    get timeRemaining() {
        return Date.now().valueOf() - this.expiry.valueOf();
    }

    get expired() {
        return this.timeRemaining < 1;
    }
}