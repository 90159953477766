import React from 'react';
import Spinner from "../Spinner/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import {Link} from "react-router-dom";

export default function ShiftsReportsTable(props) {
    const headerStyle = { fontSize: 10, margin: '-10px' }
    const colStyle = { fontSize: 12, padding: '1px', verticalAlign: 'center'}

    const linkFormatter = (cell, row) => {
        return <Link to={`shiftReport?shiftReportId=${row.id}&shiftDate=${row.date}`} target='_blank'>View Shift</Link>
    }

    const columns = [
        { dataField: 'date', text: 'Date', headerStyle: headerStyle, style: colStyle },
        { dataField: 'displayName', text: 'Name', headerStyle: headerStyle, style: colStyle },
        { dataField: 'startTime', text: 'Start Time', headerStyle: headerStyle, style: colStyle },
        { dataField: 'endTime', text: 'End Time', headerStyle: headerStyle, style: colStyle },
        { dataField: 'totalWaterUsed', text: 'Total Water Used (m³)', headerStyle: headerStyle, style: colStyle },
        { dataField: 'totalWaterUsedByProduced', text: 'Total Water Used (L/kg)', headerStyle: headerStyle, style: colStyle },
        { dataField: 'totalProducedProduct', text: 'Total Produced Product (Kg)', headerStyle: headerStyle, style: colStyle},
        { dataField: 'link', text: 'Link', headerStyle: headerStyle, style: colStyle, formatter: linkFormatter }
    ]

    return (
        <>
            { (!props.data || props.isLoading) && <Spinner variant="primary" animation="border" />}
            { (props.data && !props.isLoading) &&
                <BootstrapTable
                    keyField="id"
                    data={props.data}
                    columns={columns}
                    hover
                />
            }
        </>
    )
}