import React, {useEffect, useState} from 'react';
import Cookies from 'js-cookie';
import {ACCESS_TOKEN_COOKIE, COOKIE_OPTIONS, REFRESH_TOKEN_COOKIE} from "../../Axios/axiosConfig";

export default function SignedOutPage() {
    const [logoutComplete, setLogoutComplete] = useState(false);
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const doLogout = params.get('logout');

        if (doLogout) {
            logout().then(() => setLogoutComplete(true));
        } else {
            setLogoutComplete(true);
        }
    }, [])

    return (
        <>
            {logoutComplete ? <p>Signed out.</p> : <p>Signing out...</p>}
        </>
    )
}

async function logout() {
    expireCookie(ACCESS_TOKEN_COOKIE);
    expireCookie(REFRESH_TOKEN_COOKIE);
    const logoutUrl = new URL(process.env.REACT_APP_OAUTH_LOGOUT_URL);
    logoutUrl.searchParams.append('client_id', process.env.REACT_APP_OAUTH_CLIENT_ID);
    logoutUrl.searchParams.append('logout_uri', process.env.REACT_APP_SIGNED_OUT_URL);
    // logoutUrl.searchParams.append('redirect_uri', process.env.REACT_APP_SIGNED_OUT_URL);

    window.location.replace(logoutUrl.toString());
}

function expireCookie(cookieName) {
    const cookie = Cookies.get(cookieName);
    if (cookie) {
        Cookies.set(cookieName, cookie, {...COOKIE_OPTIONS, expires: -1});
    }
}
