import {Col, Row} from "react-bootstrap";
//import ExportToExcelButton from "../ExportToExcelButton/ExportToExcelButton";
import React from "react";
import moment from "moment/moment";
import 'bootstrap/dist/css/bootstrap.css';
import DatetimeRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';


export default class DateRangePicker extends React.Component {

    constructor(props) {
        super();
    }

    handleCallback(start, dates) {
        const startDate = new Date(dates.startDate);
        startDate.setDate(startDate.getDate());
        this.initialSettings.setStartDate(startDate);
        this.initialSettings.setEndDate(new Date(dates.endDate));
        this.initialSettings.setDateApplied(true);
    }

    render() {
        const OFFSET_START_DATE_LIMIT = new Date(2020, 7, 1);
        return (<Row>
            <Col md={this.props.hasExportCsv ? 10 : 12}>
                <DatetimeRangePicker
                    onApply={this.handleCallback}
                    initialSettings={{
                        locale: {format: 'll'},
                        maxDate: moment(),
                        maxSpan: this.props.title === 'leaks History' ? {'days': 182} : null,
                        minDate: moment(OFFSET_START_DATE_LIMIT),
                        startDate: this.props.startDate,
                        endDate: this.props.endDate,
                        setStartDate: this.props.setStartDate,
                        setEndDate: this.props.setEndDate,
                        setDateApplied: this.props.setDateApplied,
                        ranges: {
                            'Last 7 days': [moment().subtract(6, 'days').toDate(), moment().toDate(),],
                            'Last 30 Days': [moment().subtract(29, 'days').toDate(), moment().toDate(),],
                            'Last 60 Days': [moment().subtract(58, 'days').toDate(), moment().toDate(),],
                        },
                        autoApply: true
                    }}>
                    <input type="text" className="form-control"
                           style={{textAlign: 'center', fontSize: this.props.fontSize}}/>
                </DatetimeRangePicker>
            </Col>
            {/*{this.props.hasExportCsv && <Col md={1} className={'export-to-excel-button-sm'}>*/}
            {/*    <ExportToExcelButton csvData={this.props.csvData || null} title={this.props.title || null}/>*/}
            {/*</Col>}*/}
        </Row>)
    }
}
