import React, {useState} from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import Spinner from "../../Spinner/Spinner";
import filterFactory from 'react-bootstrap-table2-filter';

export default function DataTable(props) {
    const headerStyle = props.headerStyle ? props.headerStyle : {fontSize: 10, margin: '-10px'};
    const colStyle = props.colStyle ? props.colStyle : {fontSize: 12, padding: '1px', verticalAlign: 'center'};
    const [previousFilterObject, setPreviousFilterObject] = useState({});
    const [previousResult, setPreviousResult] = useState({});

    const afterFilter = (newResult, newFiltersObject) => {
        const newFiltersKeys = Object.keys(newFiltersObject);
        const previousFiltersKeys = Object.keys(previousFilterObject);
        let isObjectsSimilar = true;
        if (newResult.length !== previousResult.length || newFiltersKeys.length !== previousFiltersKeys.length) {
            isObjectsSimilar = false;
        }
        if (props.setStatusFilterValue) {
            newFiltersObject.isActive ? props.setStatusFilterValue(newFiltersObject.isActive.filterVal) : props.setStatusFilterValue('');
        }
        if (props.setAlertsFilterValue) {
            newFiltersObject.alerts ? props.setAlertsFilterValue(newFiltersObject.alerts.filterVal) : props.setAlertsFilterValue('');
        }
        if (props.setFreshWaterVolumeFilterValue) {
            newFiltersObject.freshWaterVolume ? props.setFreshWaterVolumeFilterValue(newFiltersObject.freshWaterVolume.filterVal) : props.setFreshWaterVolumeFilterValue('');
        }
        if (props.setScoreFilterValue) {
            newFiltersObject.score ? props.setScoreFilterValue(newFiltersObject.score.filterVal) : props.setScoreFilterValue('');
        }
        if (props.setRecipeFilterValue) {
            newFiltersObject.recipe ? props.setRecipeFilterValue(newFiltersObject.recipe.filterVal) : props.setRecipeFilterValue('');
        }
        if (props.setObjectNameFilterValue) {
            newFiltersObject.objectName ? props.setObjectNameFilterValue(newFiltersObject.objectName.filterVal) : props.setObjectNameFilterValue('');
        }
        if (props.setShiftFilterValue) {
            newFiltersObject.shift ? props.setShiftFilterValue(newFiltersObject.shift.filterVal) : props.setShiftFilterValue('');
        }
        if (props.setShiftFilterValue) {
            newFiltersObject.shift ? props.setShiftFilterValue(newFiltersObject.shift.filterVal) : props.setShiftFilterValue('');
        }
        if (props.setObjectGroupFilterValue) {
            newFiltersObject.objectGroup ? props.setObjectGroupFilterValue(newFiltersObject.objectGroup.filterVal) : props.setObjectGroupFilterValue('');
        }
        if (props.setStartTimeFilterValue) {
            newFiltersObject.startTime ? props.setStartTimeFilterValue(newFiltersObject.startTime.filterVal) : props.setStartTimeFilterValue('');
        }
        if (props.setLineFilterValue) {
            newFiltersObject.line ? props.setLineFilterValue(newFiltersObject.line.filterVal) : props.setLineFilterValue('');
        }
        if (props.setProductFilterValue) {
            newFiltersObject.product ? props.setProductFilterValue(newFiltersObject.product.filterVal) : props.setProductFilterValue('');
        }
        if (props.setStartDateFilterValue) {
            newFiltersObject.startDate ? props.setStartDateFilterValue(newFiltersObject.startDate.filterVal) : props.setStartDateFilterValue('');
        }
        if (props.setDurationFilterValue) {
            newFiltersObject.duration ? props.setDurationFilterValue(newFiltersObject.duration.filterVal) : props.setDurationFilterValue('');
        }
        if (props.setTotalEfficiencyFilterValue) {
            newFiltersObject.totalEfficiency ? props.setTotalEfficiencyFilterValue(newFiltersObject.totalEfficiency.filterVal) : props.setTotalEfficiencyFilterValue('');
        }
        if (props.setWeightProducedFilterValue) {
            newFiltersObject.weightProduced ? props.setWeightProducedFilterValue(newFiltersObject.weightProduced.filterVal) : props.setWeightProducedFilterValue('');
        }
        if (props.setHealthFilterValue) {
            newFiltersObject.health ? props.setHealthFilterValue(newFiltersObject.health.filterVal) : props.setHealthFilterValue('');
        }
        if (props.setAnomaliesFilterValue) {
            newFiltersObject.anomalies ? props.setAnomaliesFilterValue(newFiltersObject.anomalies.filterVal) : props.setAnomaliesFilterValue('');
        }
        if (props.setWaterUsedFilterValue) {
            newFiltersObject.freshWaterUsed ? props.setWaterUsedFilterValue(newFiltersObject.freshWaterUsed.filterVal) : props.setWaterUsedFilterValue('');
        }
        if (props.isFilterApplied && props.setIsDownloadButtonClicked) {
            props.setIsDownloadButtonClicked(true);
        }
        if (!isObjectsSimilar && props.setFilteredData) {
            props.setFilteredData(newResult)
            setPreviousResult(newResult);
            setPreviousFilterObject(newFiltersObject);
        }
    }

    const columns = props.columns.map((column, index) => {
        return {
            dataField: column.dataField,
            formatter: column.formatter,
            sort: column.sort,
            onSort: column.onSort,
            filter: column.filter,
            sortFunc: column.sortFunc,
            id: index,
            text: column.title,
            hidden: column.hidden,
            headerStyle: headerStyle,
            style: colStyle
        }
    })

    const paginationOptions = {
        page: props.currentPage ? props.currentPage : 1,
        paginationSize: props.paginationSize || 5,
        pageStartIndex: 1,
        hideSizePerPage: true,
        onPageChange: (page, sizePerPage,) => {
            if (props.setCurrentPage) {
                props.setCurrentPage(page)
            }
        },
        hidePageListOnlyOnePage: true,
        sizePerPageList: [{
            text: props.pageSize || '5', value: props.pageSize || 5
        }]
    }

    return (<>
        {((!props.data || props.isLoading) && props?.data?.length === 0) &&
            <Spinner variant="primary" animation="border"/>}
        {(props.data && !props.isLoading) && <>
            {props.title ? <h5>{props.title}</h5> : null}
            <BootstrapTable
                sort={props.defaultSort}
                rowStyle={props.rowStyle}
                filter={filterFactory({afterFilter})}
                keyField='id'
                data={props.data}
                columns={columns}
                pagination={paginationFactory(paginationOptions)}
                bootstrap4
                hover
            />
        </>}
    </>)
}
