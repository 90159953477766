import moment from "moment/moment";
import axios from "../../Axios/axiosConfig";

export class BeveragesLineService {
    baseUrl;

    constructor(serverUrl) {
        this.baseUrl = serverUrl;
    }

    async getObjectAlerts(objectId, selectedProductionLineId) {
        return axios.get(`${this.baseUrl}/production-lines/beverages/${selectedProductionLineId}/objects/${objectId}/alerts`);
    }

    async getTotalAlerts(startDate, endDate, selectedProductionLineId) {
        return axios.get(`${this.baseUrl}/production-lines/beverages/${selectedProductionLineId}/objects/alerts?from=${startDate}&to=${endDate}`);
    }

    async getStepGroupAlerts(objectId, stepGroupId, selectedProductionLineId) {
        return axios.get(`${this.baseUrl}/production-lines/beverages/${selectedProductionLineId}/objects/${objectId}/step-groups/${stepGroupId}/alerts`);
    }

    async getBeveragesObjectData(startDate, endDate, selectedProductionLineId) {
        return axios.get(`${this.baseUrl}/production-lines/beverages/${selectedProductionLineId}/objects?from=${startDate}&to=${endDate}`);
    }

    async getWeeklyTrends(selectedProductionLineId) {
        return axios.get(`${this.baseUrl}/production-lines/beverages/${selectedProductionLineId}/weekly-trends`);
    }

    async getObjectDetails(objectId, selectedProductionLineId) {
        return axios.get(`${this.baseUrl}/production-lines/beverages/${selectedProductionLineId}/objects/${objectId}/step-groups`);
    }

    async getMetersFlowRateData(selectedProductionLineId, line, startDate, endDate) {
        const newStartDate = moment.utc(startDate).subtract(1, "hour").toISOString();
        const newEndDate = moment.utc(endDate).add(1, "hour").toISOString();
        return axios.get(`${this.baseUrl}/production_line/${selectedProductionLineId}/flowDetails/between?fromDate=${newStartDate}&toDate=${newEndDate}&line=${line}&timeBucketResolution=15`);
    }

    async getGroupedMetersFlowRateData(selectedProductionLineId, line, startDate, endDate) {
        const newStartDate = moment.utc(startDate).subtract(1, "hour").toISOString();
        const newEndDate = moment.utc(endDate).add(1, "hour").toISOString();
        return axios.get(`${this.baseUrl}/production_line/${selectedProductionLineId}/flowDetails/grouped/between?fromDate=${newStartDate}&toDate=${newEndDate}&line=${line}&timeBucketResolution=15`);
    }


    async getVerticalLineData(selectedProductionLineId, selectedObjectId, startDate, endDate) {
        const newStartDate = moment.utc(startDate).subtract(1, "hour").toISOString();
        const newEndDate = moment.utc(endDate).add(1, "hour").toISOString();
        return axios.get(`${this.baseUrl}/production_line/${selectedProductionLineId}/flowDetails/between/verticalTimestamps?fromDate=${newStartDate}&toDate=${newEndDate}&objectId=${selectedObjectId}`);
    }

    async getAlertsTimestampsData(selectedProductionLineId, selectedObjectId, startDate, endDate) {
        const newStartDate = moment.utc(startDate).subtract(1, "hour").toISOString();
        const newEndDate = moment.utc(endDate).add(1, "hour").toISOString();
        return axios.get(`${this.baseUrl}/production_line/${selectedProductionLineId}/flowDetails/between/alertTimestamps?fromDate=${newStartDate}&toDate=${newEndDate}&objectId=${selectedObjectId}`);
    }

    async getCsvFile(selectedProductionLineId, startDate, endDate) {
        return axios.get(`${this.baseUrl}/production-lines/beverages/${selectedProductionLineId}/objects/csv?from=${startDate}&to=${endDate}`, {
            responseType: 'blob'
        });
    }
}


