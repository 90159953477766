import React, {useContext, useEffect, useState} from 'react';
import {Button, Card, Col, Container, NavItem, Row, Spinner} from "react-bootstrap";
import DataTable from "../../Components/Beverages/StepReportTable/DataTable";
import {textFilter} from "react-bootstrap-table2-filter";
import DateRangePicker from "../../Components/DateRangePicker/DateRangePicker";
import {FaCalendarAlt, ImDownload3} from "react-icons/all";
import './TortillaChipsHomePage.css';
import Navbar from "react-bootstrap/Navbar";
import RangeIndicator from "../../Components/RangeIndicator/RangeIndicator";
import ProgressChart from "../../Components/ProgressChart/ProgressChart";
import Badge from "react-bootstrap/Badge";
import ProductDetailsPage from "./ProductDetailsPage";
import TortillaChipsHomePageFullTable from "./TortillaChipsHomePageFullTable";
import AnomaliesModal from "./AnomaliesModal";
import {TcLineService} from "./TcLineService";
import {durationFormatter, formatDate, getLastWeekEndDate, getLastWeekStartDate, getProductionLineId, removeFractionDigits} from "../../General/general";
import {store} from "../../Store/store";
import moment from "moment/moment";

export default function TortillaChipsHomePage() {
    const [moreDetailsButtonClicked, setMoreDetailsButtonClicked] = useState(false);
    const [dateApplied, setDateApplied] = useState(true);
    const [isDownloadButtonClicked, setIsDownloadButtonClicked] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [productFilterValue, setProductFilterValue] = useState('');
    const [startDateFilterValue, setStartDateFilterValue] = useState('');
    const [durationFilterValue, setDurationFilterValue] = useState('');
    const [totalEfficiencyFilterValue, setTotalEfficiencyFilterValue] = useState('');
    const [weightProducedFilterValue, setWeightProducedFilterValue] = useState('');
    const [waterUsedFilterValue, setWaterUsedFilterValue] = useState('');
    const [healthFilterValue, setHealthFilterValue] = useState('');
    const [anomaliesFilterValue, setAnomaliesFilterValue] = useState('');
    const [isDownloadFinished, setIsDownloadFinished] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [isTcLineRequestInProgress, setIsTcLineRequestInProgress] = useState(false);
    const [isProductAnomaliesRequestInProgress, setIsProductAnomaliesRequestInProgress] = useState(false);
    const tcLineService = new TcLineService(process.env.REACT_APP_API_URL);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState(data);
    const globalState = useContext(store);
    const selectedProductionLineId = getProductionLineId(globalState);
    const [startDate, setStartDate] = useState(getLastWeekStartDate());
    const [endDate, setEndDate] = useState(getLastWeekEndDate());
    const [anomalyData, setAnomalyData] = useState([]);
    const [isProductLineChanged, setIsProductLineChanged] = useState(false);
    const [isFirstRequest, setIsFirstRequest] = useState(true);
    const [sortState, setSortState] = useState({});

    const getTcLineData = async (startDate, endDate, selectedProductionLineId) => {
        try {
            const utcStartDate = moment(startDate).startOf('day').toISOString();
            const utcEndDate = moment(endDate).endOf('day').toISOString();
            setData([]);
            setIsTcLineRequestInProgress(true);
            setCurrentPage(1);
            const response = await tcLineService.getTcLineData(utcStartDate, utcEndDate, selectedProductionLineId);
            let data = response.data.map(obj => {
                return {...obj, formattedDuration: durationFormatter(obj.duration), startDate: formatDate(obj.startDate)};
            })
            setData(data);
            setFilteredData(data);
            setIsTcLineRequestInProgress(false);
        } catch (error) {
            setIsTcLineRequestInProgress(false);
            console.error(error);
        }

    }
    const onSort = (field, order) => {
        setSortState({dataField: field, order: order})
    }

    const dateSorter = (a, b, order) => {
        return order === 'asc' ? Date.parse(a) - Date.parse(b) : Date.parse(b) - Date.parse(a);
    }

    const totalEfficiencyFormatter = (cell, row) => {
        return row.productionType && row.productionType > 0 ? <RangeIndicator minValue={row.totalEfficiencyMinValue} value={cell.toFixed(1)} maxValue={row.totalEfficiencyMaxValue}/> : null;
    }

    const waterUsedFormatter = (cell) => {
        return removeFractionDigits(cell / 1000);
    }

    const WeightProducedFormatter = (cell) => {
        return cell.toLocaleString();
    }

    const getColorByValue = (value) => {
        let color;
        if (value >= 85) {
            color = 'green';
        } else if (value < 85 && value >= 55) {
            color = 'yellow';
        } else {
            color = 'red';
        }
        return color;
    }

    const getHealthScoreCardClass = () => {
        let alertsFound = false;
        filteredData.forEach(obj => {
            if (obj.anomalies > 0 && obj.isProdInProgress) {
                alertsFound = true;
            }
        })
        if (!alertsFound) {
            return 'sub-green-card-style';
        } else {
            return 'sub-red-card-style';
        }
    }

    const getNumberOfBatches = () => {
        let count = 0;
        filteredData.forEach(obj => {
            if (obj.productionType && obj.productionType > 0) {
                count++;
            }
        })
        return count;
    }

    useEffect(() => {
        if (isDownloadFinished) {
            setIsDownloadButtonClicked(false);
        }
        setIsDownloadFinished(false);
    }, [isDownloadFinished])

    useEffect(() => {
        setIsFirstRequest(false);
        if ((dateApplied || isProductLineChanged)) {
            setData([]);
            setFilteredData([]);
            setDateApplied(false);
            setIsProductLineChanged(false);
            getTcLineData(startDate, endDate, selectedProductionLineId);
        }
    }, [dateApplied, isProductLineChanged])

    useEffect(() => {
        if (!isFirstRequest) {
            setIsProductLineChanged(true);
            setMoreDetailsButtonClicked(false);
        }
    }, [selectedProductionLineId])


    const healthFormatter = (cell) => {
        let variant = getColorByValue(cell?.toFixed(0));
        return <ProgressChart percentage={cell ? cell : 0} variant={variant} value={cell}/>
    }

    const handleMoreDetailsButtonClick = (row) => {
        setSelectedRow(row);
        setMoreDetailsButtonClicked(true);
    }


    const productNameFormatter = (cell, row) => {
        return <Button onClick={() => handleMoreDetailsButtonClick(row)} variant={"link"} style={{textDecoration: 'underline'}}>{cell ? cell : row.id}</Button>
    }

    const notificationFormatter = (cell, row) => {
        if (row.isProdInProgress && row.anomalies === 0) {
            return <h5><Badge pill variant={"success"}>Production In Progress</Badge></h5>;
        }
        if (row.isProdInProgress && row.anomalies > 0) {
            return <h5><Badge pill variant={"danger"}>Production In Progress</Badge></h5>;

        }
    }

    const anomaliesFormatter = (cell, row) => {
        if (cell > 0) {
            return <div className='justify-content-center align-items-center'>
                <div className={'d-inline-block'}>
                    {cell}
                </div>
                <div className={'d-inline-block'}>
                    <Button style={{height: '24.5px'}} className='pl-1 pr-0 pt-0 pb-0' onClick={() => {
                        getProductAnomalies(row.id, selectedProductionLineId);
                        setShowModal(true);
                    }} size={'sm'} variant={'link'}><h6><Badge pill variant={"danger"}> i</Badge></h6>
                    </Button>
                </div>
            </div>
        } else {
            return cell
        }
    }

    let pageSize = data.length > 8 ? 7 : 8;

    const productFilter = textFilter({
        style: {margin: '0 auto', height: 30, fontSize: 12}, // your custom styles on input
        defaultValue: productFilterValue, // default filtering value
    })

    const startDateFilter = textFilter({
        style: {margin: '0 auto', height: 30, fontSize: 12}, defaultValue: startDateFilterValue
    })

    const durationFilter = textFilter({
        style: {margin: '0 auto', height: 30, fontSize: 12}, defaultValue: durationFilterValue
    })

    const totalEfficiencyFilter = textFilter({
        style: {margin: '0 auto', height: 30, fontSize: 12}, defaultValue: totalEfficiencyFilterValue
    })

    const weightProducedFilter = textFilter({
        style: {margin: '0 auto', height: 30, fontSize: 12}, defaultValue: weightProducedFilterValue
    })

    const waterUsedFilter = textFilter({
        style: {margin: '0 auto', height: 30, fontSize: 12}, defaultValue: waterUsedFilterValue
    })


    const healthFilter = textFilter({
        style: {margin: '0 auto', height: 30, fontSize: 12}, defaultValue: healthFilterValue
    })

    const anomaliesFilter = textFilter({
        style: {margin: '0 auto', height: 30, fontSize: 12}, defaultValue: anomaliesFilterValue
    })

    const headerStyle = {fontSize: 15, border: 'none'}
    const colStyle = {fontSize: 15, fontWeight: 'bold', background: 'white', verticalAlign: 'center'}
    const columns = [
        {dataField: 'id', title: 'ID', hidden: true},
        {dataField: 'product', title: 'Product', text: 'product', formatter: productNameFormatter, sort: true, filter: productFilter, onSort: onSort},
        {dataField: 'startDate', title: 'Start Date', sort: true, filter: startDateFilter, sortFunc: dateSorter, onSort: onSort},
        {dataField: 'formattedDuration', title: 'Duration [HH:MM]', sort: true, filter: durationFilter, onSort: onSort},
        {dataField: 'totalEfficiency', title: 'Total Efficiency [L/KG]', formatter: totalEfficiencyFormatter, sort: true, filter: totalEfficiencyFilter, onSort: onSort},
        {dataField: 'weightProduced', title: 'Weight Produced [KG]', formatter: WeightProducedFormatter, sort: true, filter: weightProducedFilter, onSort: onSort},
        {dataField: 'freshWaterUsed', title: `Water Used [㎥]`, formatter: waterUsedFormatter, sort: true, filter: waterUsedFilter, onSort: onSort},
        {dataField: 'health', title: 'Health', formatter: healthFormatter, sort: true, filter: healthFilter, onSort: onSort},
        {dataField: 'anomalies', sort: true, title: '# Alerts', formatter: anomaliesFormatter, filter: anomaliesFilter, onSort: onSort},
        {dataField: 'notification', title: '', formatter: notificationFormatter}
    ];

    const calculateWeightProduced = () => {
        let result = 0;
        filteredData.forEach(obj => {
            result += obj.weightProduced;
        })
        if (result < 1000 && result > 0) {
            return [result, 'KG'];
        }
        return [parseFloat((result / 1000).toFixed(1)).toLocaleString(), 'TON'];
    }

    const calculateHealthScore = () => {
        let totalValueByDuration = 0;
        let totalDuration = 0;
        filteredData.forEach(obj => {
            totalValueByDuration += obj.health * obj.duration;
            totalDuration += obj.duration;
        })
        if (totalValueByDuration === 0 && totalDuration === 0) {
            return 0;
        }
        return (totalValueByDuration / totalDuration);
    }

    const calculateTotalEfficiency = () => {
        let totalWightProduced = 0;
        let totalFreshWaterUsed = 0;
        filteredData.forEach(obj => {
            totalWightProduced += obj.weightProduced;
            totalFreshWaterUsed += obj.freshWaterUsed;
        })
        if (totalWightProduced === 0 && totalFreshWaterUsed === 0) {
            return 0;
        }
        return parseFloat((totalFreshWaterUsed / totalWightProduced).toFixed(1)).toLocaleString();
    }

    const calculateRecuperatedWater = () => {
        let totalRecuperatedWater = 0;
        let totalFreshWaterUsed = 0;
        filteredData.forEach(obj => {
            totalRecuperatedWater += obj.recuperatedWater;
            totalFreshWaterUsed += obj.freshWaterUsed;
        })
        if (totalRecuperatedWater === 0 && totalFreshWaterUsed === 0) {
            return 0;
        }
        return removeFractionDigits((totalRecuperatedWater / (totalFreshWaterUsed + totalRecuperatedWater)) * 100);
    }

    const calculateProductLineUtilization = () => {
        let totalDuration = 0;
        let totalPeriod = 0;
        filteredData.forEach(obj => {
            if (obj.product !== 'Downtime') {
                totalDuration += obj.duration * 60;
            }
            totalPeriod += obj.productRuntime;
        })
        if (totalDuration === 0 && totalPeriod === 0) {
            return 0
        }
        return removeFractionDigits((totalPeriod / totalDuration) * 100);
    }

    const getFreshWaterUsed = () => {
        let totalValue = 0;
        filteredData.forEach(obj => {
            totalValue += obj.freshWaterUsed;
        })
        return removeFractionDigits(totalValue / 1000);
    }

    const getTotalAnomalies = () => {
        let totalAnomalies = 0;
        filteredData.forEach(obj => {
            totalAnomalies += obj.anomalies;
        })
        return totalAnomalies.toLocaleString();
    }

    const getAlertNumber = () => {
        let alertCount = 0;
        filteredData.forEach(obj => {
            if (obj.anomalies > 0 && obj.isProdInProgress) {
                alertCount += 1;
            }
        })
        if (alertCount === 0) {
            return <h5><Badge className={'pl-5 pr-5'} pill variant={'info'}>No Open Alerts</Badge></h5>
        } else if (alertCount === 1) {
            return <h5><Badge className={'pl-5 pr-5'} pill variant={'info'}>{`${alertCount} Open Alert`}</Badge></h5>
        } else {
            return <h5><Badge className={'pl-5 pr-5'} pill variant={'info'}>{`${alertCount} Open Alerts`}</Badge></h5>
        }
    }

    const getProductAnomalies = async (productId, selectedProductionLineId) => {
        try {
            setAnomalyData([]);
            setIsProductAnomaliesRequestInProgress(true);
            const response = await tcLineService.getProductAnomalies(productId, selectedProductionLineId);
            setIsProductAnomaliesRequestInProgress(false);
            setAnomalyData(response.data);
        } catch (error) {
            setIsProductAnomaliesRequestInProgress(false);
            console.error(error);
        }
    }


    const healthScore = calculateHealthScore();

    const renderWidgets = () => {
        return (<Row>
            <Col className={'pl-2 pr-2'}>
                <Card className={'ml-3'} bsPrefix={getHealthScoreCardClass()}>
                    <Card.Body>
                        <Card.Subtitle bsPrefix={'card-text-info'}>Health Score</Card.Subtitle>
                        <div style={{display: 'inline-block'}} className={'mr-1'}>
                            <Card.Text bsPrefix={'card-text-info'} as={'h1'}>{healthScore.toFixed(0)}</Card.Text>
                        </div>
                        <div>
                            {getAlertNumber()}
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Col className={'pl-2 pr-2'}>
                <Card bsPrefix={'sub-card-style'} bg={'white'}>
                    <Card.Body>
                        <Card.Subtitle bsPrefix={'card-text-info'} className={'mb-3'}> Total Efficiency </Card.Subtitle>
                        <div style={{display: 'inline-block'}} className={'mr-1'}>
                            <Card.Text bsPrefix={'card-text-info'} as={'h1'}>{calculateTotalEfficiency()}</Card.Text>
                        </div>
                        <div style={{display: 'inline-block'}}>
                            <Card.Text bsPrefix={'card-text-info'}>L/KG</Card.Text>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Col className={'pl-2 pr-2'}>
                <Card bsPrefix={'sub-card-style'} bg={'white'}>
                    <Card.Body>
                        <Card.Subtitle bsPrefix={'card-text-info'} className={'mb-3'}> Anomalies </Card.Subtitle>
                        <Card.Text bsPrefix={'card-text-info'} as={'h1'}> {getTotalAnomalies()}</Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            <Col className={'pl-2 pr-2'}>
                <Card bsPrefix={'sub-card-style'} bg={'white'}>
                    <Card.Body>
                        <Card.Subtitle bsPrefix={'card-text-info'} className={'mb-3'}> # Batches </Card.Subtitle>
                        <Card.Text bsPrefix={'card-text-info'} as={'h1'}> {getNumberOfBatches()} </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            <Col className={'pl-2 pr-2'}>
                <Card bsPrefix={'sub-card-style'} bg={'white'}>
                    <Card.Body>
                        <Card.Subtitle bsPrefix={'card-text-info'} className={'mb-3'}> Recuperated Water </Card.Subtitle>
                        <Card.Text bsPrefix={'card-text-info'} as={'h1'}> {calculateRecuperatedWater()}% </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            <Col className={'pl-2 pr-2'}>
                <Card bsPrefix={'sub-card-style'} bg={'white'}>
                    <Card.Body>
                        <Card.Subtitle bsPrefix={'card-text-info'} className={'mb-3'}> Production Line Utilization </Card.Subtitle>
                        <Card.Text bsPrefix={'card-text-info'} as={'h1'}> {calculateProductLineUtilization()}% </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            <Col className={'pl-2 pr-2'}>
                <Card bsPrefix={'sub-card-style'} bg={'white'}>
                    <Card.Body>
                        <Card.Subtitle bsPrefix={'card-text-info'} className={'mb-3'}> Weight Produced </Card.Subtitle>
                        <div style={{display: 'inline-block'}} className={'mr-1'}>
                            <Card.Text bsPrefix={'card-text-info'} as={'h1'}>{calculateWeightProduced()[0]}</Card.Text>
                        </div>
                        <div style={{display: 'inline-block'}}>
                            <Card.Text bsPrefix={'card-text-info'}> {calculateWeightProduced()[1]}</Card.Text>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Col className={'pl-2'}>
                <Card className={'mr-2'} bsPrefix={'sub-card-style'} bg={'white'}>
                    <Card.Body>
                        <Card.Subtitle bsPrefix={'card-text-info'} className={'mb-3'}>Fresh Water Used </Card.Subtitle>
                        <div style={{display: 'inline-block'}} className={'mr-1'}>
                            <Card.Text bsPrefix={'card-text-info'} as={'h1'}>{getFreshWaterUsed().toLocaleString()}</Card.Text>
                        </div>
                        <div style={{display: 'inline-block'}} className={'mt-3'}>
                            <Card.Text as={'h4'} bsPrefix={'card-text-info'}>&#13221;</Card.Text>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>)
    }

    if (moreDetailsButtonClicked) {
        return <ProductDetailsPage getProductAnomalies={getProductAnomalies}
                                   tcLineService={tcLineService}
                                   anomaliesFormatter={anomaliesFormatter}
                                   healthFormatter={healthFormatter}
                                   selectedRow={selectedRow}
                                   setMoreDetailsButtonClicked={setMoreDetailsButtonClicked}
                                   durationFormatter={durationFormatter}
                                   totalEfficiencyFormatter={totalEfficiencyFormatter}
                                   selectedProductionLineId={selectedProductionLineId}

        />
    } else if (isDownloadButtonClicked) {
        return <TortillaChipsHomePageFullTable data={filteredData}
                                               setIsDownloadFinished={setIsDownloadFinished}
                                               productFilterValue={productFilterValue}
                                               startDateFilterValue={startDateFilterValue}
                                               durationFilterValue={durationFilterValue}
                                               totalEfficiencyFilterValue={totalEfficiencyFilterValue}
                                               weightProducedFilterValue={weightProducedFilterValue}
                                               waterUsedFilterValue={waterUsedFilterValue}
                                               healthFilterValue={healthFilterValue}
                                               anomaliesFilterValue={anomaliesFilterValue}
                                               startDate={startDate}
                                               endDate={endDate}
                                               renderWidgets={renderWidgets}
                                               columns={columns}
        />
    } else {
        return (<Card style={{backgroundColor: isTcLineRequestInProgress ? 'white' : '#f5f7fb'}} bsPrefix={'main-card-style'}>
            <AnomaliesModal isAnomaliesRequestInProgress={isProductAnomaliesRequestInProgress} anomalyData={anomalyData} show={showModal} setShow={setShowModal}/>
            <Container fluid>
                <Col>
                    <Row>
                        <Col>
                            <Navbar>
                                <Container fluid>
                                    <Navbar.Collapse>
                                        <NavItem>
                                            <FaCalendarAlt size={30} className={'mr-3'}/>
                                        </NavItem>
                                        <NavItem style={{width: '20%'}}>
                                            <DateRangePicker startDate={startDate} endDate={endDate} setStartDate={setStartDate}
                                                             setDateApplied={setDateApplied}
                                                             setEndDate={setEndDate}
                                            />
                                        </NavItem>
                                    </Navbar.Collapse>
                                    <Button disabled={isTcLineRequestInProgress} onClick={() => {
                                        setIsDownloadButtonClicked(true);
                                    }}><ImDownload3/> Download</Button>
                                </Container>
                            </Navbar>
                        </Col>
                    </Row>
                </Col>
            </Container>
            <br/>
            {isTcLineRequestInProgress ? <Spinner animation="border" variant="primary"/> : <>
                {renderWidgets()}
                <Row className={'pt-3'}>
                    <Col>
                        <DataTable
                            headerStyle={headerStyle}
                            colStyle={colStyle}
                            data={data}
                            columns={columns}
                            title={''}
                            defaultSort={sortState}
                            setProductFilterValue={setProductFilterValue}
                            setStartDateFilterValue={setStartDateFilterValue}
                            setDurationFilterValue={setDurationFilterValue}
                            setTotalEfficiencyFilterValue={setTotalEfficiencyFilterValue}
                            setWeightProducedFilterValue={setWeightProducedFilterValue}
                            setWaterUsedFilterValue={setWaterUsedFilterValue}
                            setHealthFilterValue={setHealthFilterValue}
                            setAnomaliesFilterValue={setAnomaliesFilterValue}
                            pageSize={pageSize}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            setFilteredData={setFilteredData}
                            paginationSize={10}/>
                    </Col>
                </Row>
            </>}

        </Card>)
    }

}
