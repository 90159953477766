import {Col, ProgressBar, Row} from "react-bootstrap";
import React from "react";

export default function ProgressChart(props) {
    return (
        <Row>
            <Col md={12}>
                <Row>
                    <Col md={12}>
                        <div style={{textAlign: 'right', marginRight: '1em'}}>{props.percentage}%</div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <ProgressBar variant={props.variant} now={props.percentage}/>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
