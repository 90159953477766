import React from 'react';
import {ProjectColors} from "../../General/projectColors";

export default function Circle(props) {
    const circleStyle = {
        padding:10,
        margin:20,
        display:"inline-block",
        backgroundColor: props.bgColor,
        borderRadius: "50%",
        width: props.width || 30,
        height:props.height || 30,
        left:0,
        top:0,
        position: 'sticky',
        marginTop: '-30px',
        borderWidth: '1px',
        borderColor: ProjectColors.Gray,
        borderStyle: 'solid'
    };
    return (
        <div style={circleStyle}>
        </div>
    );
}
