import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import React, {useRef} from "react";
import moment from "moment";

const ExportDropdown = ({
                            setIsDownloadButtonClicked,
                            beveragesLineService,
                            startDate,
                            endDate,
                            selectedProductionLineId
                        }) => {
    const downloadLinkRef = useRef(null);

    const downloadExcelFile = async () => {
        const utcStartDate = moment(startDate).utcOffset(0, true).toISOString();
        const utcEndDate = moment(endDate).utcOffset(0, true).toISOString();

        try {
            const response = await beveragesLineService.getCsvFile(selectedProductionLineId, utcStartDate, utcEndDate);
            const url = window.URL.createObjectURL(response.data);
            downloadLinkRef.current.href = url;
            downloadLinkRef.current.download = `${selectedProductionLineId}_object_details_from_${moment(startDate).format('L')}_to_${moment(endDate).format('L')}.xlsx`;
            downloadLinkRef.current.click();
            window.URL.revokeObjectURL(url);
        } catch (e) {
            console.error('Error during file download:', e);
        }
    }

    return <><DropdownButton title={'Export'} className='btn-primary mr-5'>
        <Dropdown.Item onClick={() => setIsDownloadButtonClicked(true)}>Export to PDF</Dropdown.Item>
        <Dropdown.Item onClick={() => downloadExcelFile()}>Export to Excel</Dropdown.Item>
    </DropdownButton>
        <a ref={downloadLinkRef} style={{display: 'none'}}>Download Link</a>
    </>

}

export default ExportDropdown
