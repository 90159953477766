import React, {useContext, useEffect, useState} from 'react';
import {SET_PRODUCTION_LINE} from "../../Store/ActionTypes/actionTypes";
import Dropdown from "react-dropdown";
import axios, {handleError} from "../../Axios/axiosConfig";
import {store} from "../../Store/store";

export default function ProductionLineSelector(props) {
    const globalState = useContext(store);
    const serverUrl = globalState.state.serverUrl;
    const {dispatch} = globalState;
    const [selectedProductionLine, setSelectedProductionLine] = useState();
    const [productionLineOptions, setProductionLineOptions] = useState();

    const loadProductionLines = async () => {
        await axios.get(`${serverUrl}/production-lines`).then(response => {
            let productionLines = [];
            response.data.forEach(line => {
                productionLines.push({id: line.name, label: line.label, lineType: line.lineType})
            })
            // Sort by PC label
            productionLines.sort(function (a, b) {
                if (a.label < b.label) {
                    return -1;
                }
                if (a.label > b.label) {
                    return 1;
                }
                return 0;
            })
            setProductionLineOptions(productionLines);
            if (!globalState.state.selectedProductionLine && productionLines.length > 0) {
                const selectedProductionLineTemp = productionLines[0];
                setSelectedProductionLine(selectedProductionLineTemp);
                dispatch({type: SET_PRODUCTION_LINE, payload: selectedProductionLineTemp})
            }
        }).catch(error => handleError(error))
    }

    useEffect(() => {
        const productionLine = getLineByLabel(globalState.state.selectedProductionLine?.label ||
            JSON.parse(globalState.state.selectedProductionLine)?.label);
        if (!productionLine) {
            return;
        }

        setSelectedProductionLine(productionLine);
    }, [globalState.state.selectedProductionLine, productionLineOptions]);

    const getLineByLabel = (label) => {
        if (!label)
            return null;

        return productionLineOptions?.filter(line => line.label === label)[0];
    }

    useEffect(() => {
        if (props.isCookieSaved) {
            loadProductionLines();
        }
    }, [props.isCookieSaved]);

    const handleProductionLineChange = (productionLine) => {
        const selectedProductionLine = getLineByLabel(productionLine.label);
        setSelectedProductionLine(selectedProductionLine);
        dispatch({type: SET_PRODUCTION_LINE, payload: selectedProductionLine})
    };

    return (
        <>
            {globalState.state.selectedProductionLine &&
                <Dropdown
                    disabled={!globalState.state.selectedProductionLine}
                    options={productionLineOptions}
                    onChange={(option) => {
                        handleProductionLineChange(option);
                    }}
                    value={selectedProductionLine}/>}
        </>
    )
}
