import {Button, Col, Modal, Row, Spinner} from "react-bootstrap";
import React from "react";
import DataTable from "../../Components/Beverages/StepReportTable/DataTable";
import {cellFormatter, cellTimeFormatter} from "../../General/general";

export default function AnomaliesModal(props) {
    const headerStyle = {fontSize: 15, border: 'none', textAlign: 'center'};
    const colStyle = {fontSize: 15, background: 'white', verticalAlign: 'center', maxWidth: 100, textAlign: 'center'};

    const columns =
        [
            {dataField: 'id', title: 'ID', hidden: true},
            {dataField: 'startTime', title: 'Start Time', sort: true, formatter: cellTimeFormatter},
            {dataField: 'endTime', title: 'End Time', sort: true, formatter: cellTimeFormatter},
            {dataField: 'type', title: 'Type', sort: true, formatter: cellFormatter},
            {dataField: 'message', title: 'Description', sort: true, formatter: cellFormatter},
        ];

    return (
        <Modal className={'SettingModal'} dialogClassName={'modal-settings'} show={props.show} backdrop={true} onHide={() => props.setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Alerts Info</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    props.isAnomaliesRequestInProgress ?
                        <div style={{textAlign: 'center'}}><Spinner animation={"border"} variant={"primary"}/></div>
                        :
                        <Row className={'pt-2'}>
                            <Col>
                                <DataTable
                                    headerStyle={headerStyle}
                                    colStyle={colStyle}
                                    data={props.anomalyData}
                                    columns={columns}
                                    paginationSize={10}
                                />
                            </Col>
                        </Row>
                }

            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => props.setShow(false)}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}
