import React, {useContext} from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import ProductionLineSelector from "../ProductionLineSelector/ProductionLineSelector";
import './WintNavbar.css'
import 'react-dropdown/style.css';
import {Button, Form} from "react-bootstrap";
import {BiLogOutCircle} from "react-icons/all";
import {store} from "../../Store/store";

export default function WintNavbar(props) {
    const globalState = useContext(store);
    const lineType = globalState.state.selectedProductionLine?.lineType ||
        JSON.parse(globalState.state.selectedProductionLine)?.lineType;

    const getClassName = (route) => {
        if (window.location.toString().endsWith(route)) {
            return "navbar-selected-tab"
        }
    }

    const renderNavBarLogo = () => {
        const company = process.env.REACT_APP_COMPANY_NAME;
        switch (company) {
            case 'Coca-Cola':
                return <img
                    src={require("../../Images/coca-cola-logo-small.png")}
                    alt="logo"
                />
            case 'PepsiCo':
                return <img
                    src={require("../../Images/wint-pepsico-logo-small.png")}
                    alt="logo"
                />
            default:
                return null
        }
    }

    return (
        <Navbar bg="light" variant="light" expand="lg">
            <Navbar.Brand>
                {renderNavBarLogo()}
            </Navbar.Brand>
            <Nav className="mr-auto">
                <ProductionLineSelector isCookieSaved={props.isCookieSaved}/>
                <Nav.Link href="/dashboard" className={getClassName("/dashboard")}>Home</Nav.Link>
                {lineType === 'beverages' &&
                    <Nav.Link href="/weeklyTrend" className={getClassName("/weeklyTrend")}>Weekly Trend</Nav.Link>}
                {/*<Nav.Link href="/valveStatus" className={getClassName("/valveStatus")}>Valves Status</Nav.Link>*/}
                {/*<Nav.Link href="/settings" className={getClassName("/settings")}>Settings</Nav.Link>*/}
                {/*<Nav.Link href="/uploadStats" className={getClassName("/uploadStats")}>Upload statistics</Nav.Link>*/}
            </Nav>
            {!props.isSignedOutPage &&
                <Nav.Link href="/signed-out?logout=true">
                    <Form inline={true}>
                        <Button>
                            <BiLogOutCircle/>
                        </Button>
                    </Form>
                </Nav.Link>
            }
            {props.isSignedOutPage
                && <Nav.Link href="/">
                    <Form inline>
                        <Button variant={"success"} onClick={() => window.location.reload()}>
                            Log In
                        </Button>
                    </Form>
                </Nav.Link>
            }
        </Navbar>
    )
}
