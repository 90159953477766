import React, {useContext, useState, useEffect, useRef} from 'react';
import WintNavbar from './Components/WintNavbar/WintNavbar'
import './App.css';
import Cookies from 'js-cookie';
import {store} from "./Store/store";
import SignedOutPage from "./Pages/SignedOut/SignedOutPage";
import axios, {
    ACCESS_TOKEN_COOKIE,
    COOKIE_OPTIONS, EXPIRY_WARNING_PERIOD, handleError,
    LOGIN_CSRF_COOKIE,
    REFRESH_TOKEN_COOKIE, storeAccessToken, TOKEN_TYPE_COOKIE
} from "./Axios/axiosConfig";
import {SET_USER_EMAIL} from "./Store/ActionTypes/actionTypes";
import {Expiring} from "./Axios/expiring";
import './General/fonts.css'
import Routes from "./Routes";

function App() {
    const {dispatch} = useContext(store);
    const globalState = useContext(store);
    const serverUrl = useRef(`${globalState.state.serverUrl}`);
    const [initComplete, setInitComplete] = useState(false);
    const isSignedOutPage = (document.location.pathname === "/signed-out");
    const Tokens = require('csrf')
    const [isCookieSaved, setIsCookieSaved] = useState(false);

    useEffect(() => {
        if (!isSignedOutPage) {
            function login() {
                const tokens = new Tokens();
                const csrf = tokens.secretSync()
                Cookies.set(LOGIN_CSRF_COOKIE, csrf, COOKIE_OPTIONS);

                const loginUrlParams = new URLSearchParams({
                    response_type: 'code',
                    client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
                    redirect_uri: process.env.REACT_APP_OAUTH_SERVER_URL +
                        process.env.REACT_APP_OAUTH_CLIENT_REDIRECT_PATH +
                        '?clientId=' + process.env.REACT_APP_OAUTH_CLIENT_ID,
                    scope: process.env.REACT_APP_OAUTH_CLIENT_SCOPE,
                    state: csrf
                });

                const loginUrl = new URL(process.env.REACT_APP_OAUTH_LOGIN_URL);
                loginUrlParams.forEach((value, key) => loginUrl.searchParams.append(key, value));
                window.location.replace(loginUrl.toString());
            }

            const sessionAccessToken = Cookies.get(ACCESS_TOKEN_COOKIE);
            const sessionRefreshToken = Cookies.get(REFRESH_TOKEN_COOKIE);
            const sessionTokenType = Cookies.get(TOKEN_TYPE_COOKIE);
            if (sessionAccessToken && sessionRefreshToken && sessionTokenType) {
                setInitComplete(true);
                setIsCookieSaved(true);
            } else {
                const params = new URLSearchParams(window.location.search);
                const sessionId = params.get('sessionId');
                let accessToken;
                let accessTokenLifetime
                let refreshToken;
                let refreshTokenLifetime;
                let csrf;
                if (!sessionId) {
                    setInitComplete(false);
                    login();
                }
                const url = new URL(`${process.env.REACT_APP_OAUTH_SERVER_URL}${process.env.REACT_APP_OAUTH_CLIENT_REDIRECT_PATH}/sessions/${sessionId}`);
                fetch(url).then(async response => {
                    const res = await response.json();
                    accessToken = res.accessToken;
                    accessTokenLifetime = res.accessTokenLifetime;
                    refreshToken = res.refreshToken;
                    refreshTokenLifetime = res.refreshTokenLifetime;
                    csrf = res.csrf;
                    if (accessToken != null) {
                        const expectedCsrf = Cookies.get(LOGIN_CSRF_COOKIE);
                        setInitComplete(storeAccessToken(
                            Expiring.fromNow(accessToken, accessTokenLifetime - EXPIRY_WARNING_PERIOD),
                            Expiring.fromNow(refreshToken, refreshTokenLifetime - EXPIRY_WARNING_PERIOD),
                            csrf, expectedCsrf));
                    }
                    setIsCookieSaved(true);
                }).catch(err => {
                    console.error('err', err)
                }).finally(() => {
                        const location = new URL(window.location.href);
                        location.searchParams.delete('sessionId');
                        const nextURL = location.toString();
                        const nextTitle = document.title;
                        const nextState = {additionalInformation: 'removed login params'};
                        // create a new entry in the browser's history, without reloading
                        window.history.pushState(nextState, nextTitle, nextURL);
                    }
                )
            }
        }
    }, [isSignedOutPage, Tokens]);

    useEffect(() => {
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute('content', `${process.env.REACT_APP_COMPANY_NAME} Water Consumption Dashboard`);
            document.title = `Wint - ${process.env.REACT_APP_COMPANY_NAME} Dashboard`;
        }
    }, []);

    useEffect(() => {
        if (!isSignedOutPage && isCookieSaved) {
            axios.get(`${serverUrl.current}/user-preference`).then(response => {
                dispatch({type: SET_USER_EMAIL, payload: response.data.email})
            }).catch(error => handleError(error))
        }
    }, [isSignedOutPage, dispatch, isCookieSaved])

    let body;
    if (isSignedOutPage) {
        body = <SignedOutPage/>
    } else {
        body = initComplete ? <Routes/> : <p>Loading...</p>
    }

    return (
        <div className="App" style={{fontFamily: 'Poppins'}}>
            <WintNavbar isCookieSaved={isCookieSaved} isSignedOutPage={isSignedOutPage}/>
            {body}
        </div>
    );
}

export default App;
