import React from "react";
import {RiArrowLeftSFill, RiArrowRightSFill, RiArrowUpSFill} from "react-icons/all";
import {Col, Row} from "react-bootstrap";

export default function RangeIndicator(props) {
    const maxValue = props.maxValue;
    const minValue = props.minValue;
    const value = props.value;
    const red = '#de6261';
    const yellow = '#e6cc72';
    const green = '#58c948';
    const arrowIconSize = 30;
    const redStartingAreaWidth = 10;
    const yellowStartingAreaWidth = 30;
    const greenAreaWidth = 70;
    const yellowEndingAreaWidth = 80;
    const redEndingAreaWidth = 100;
    const middleAreaPercentage = 65;
    const singleDigitWidth = 46;
    const doubleDigitWidth = 48;
    const threeDigitWidth = 50;


    const calculatePercentage = (maxValue, value, minvalue) => {
        if (minValue === maxValue && minValue === value) {
            return middleAreaPercentage;
        }
        return ((value - minvalue) * 100 / (maxValue - minvalue)).toFixed(2);
    }

    const setClassByMinValue = (minValue) => {
        if (minValue.toLocaleString().length <= 2) {
            return 'ml-1';
        }
        if (minValue.toLocaleString().length <= 3) {
            return 'ml-1';
        }
        if (minValue.toLocaleString().length <= 4) {
            return 'ml-2';
        }
        if (minValue.toLocaleString().length <= 5) {
            return 'ml-4';
        } else return 'ml-4';
    }


    const renderArrow = () => {
        let width;
        let style;
        if (minValue === 0 && maxValue === 0 && value === 0) {
            style = {width: 0 + '%', textAlign: 'right', marginLeft: '-15px', marginTop: '5px'}
            return <div style={style}>
                <RiArrowUpSFill size={arrowIconSize}/>
            </div>
        } else if (value < minValue) {
            width = calculatePercentage(maxValue, minValue, minValue);
            style = {width: width + '%', textAlign: 'right', marginLeft: '-15px', marginTop: '5px'}
            return <div style={style}>
                <RiArrowLeftSFill size={arrowIconSize}/>
            </div>
        } else if (value > maxValue) {
            width = calculatePercentage(maxValue, maxValue, minValue);
            style = {width: width + '%', textAlign: 'right', marginLeft: '15px', marginTop: '5px'}
            return <div style={style}>
                <RiArrowRightSFill size={arrowIconSize}/>
            </div>
        } else {
            width = calculatePercentage(maxValue, value, minValue);
            style = {width: width + '%', textAlign: 'right', marginLeft: minValue !== value ? '15px' : '-15px'}
            return <div style={style}>
                <RiArrowUpSFill size={arrowIconSize}/>
            </div>
        }
    }

    const getLabelColor = (width) => {
        if (width <= redStartingAreaWidth) {
            return red;
        } else if (width >= redStartingAreaWidth && width < yellowStartingAreaWidth) {
            return yellow;
        } else if (width >= yellowStartingAreaWidth && width < greenAreaWidth) {
            return green;
        } else if (width >= greenAreaWidth && width < yellowEndingAreaWidth) {
            return yellow;
        } else if (width >= yellowEndingAreaWidth && width <= redEndingAreaWidth) {
            return red;
        } else {
            return 'black';
        }
    }

    const renderLabel = () => {
        let width;
        let style;
        if (minValue === 0 && maxValue === 0 && value === 0) {
            style = {color: red, width: redStartingAreaWidth + '%', textAlign: 'right', marginLeft: '-15px'}
            return <div style={style}>
                {value?.toLocaleString()}
            </div>
        } else if (minValue === maxValue && minValue === value) {
            if (value < 10) {
                width = singleDigitWidth;
            }
            if (value >= 10 && value < 100) {
                width = doubleDigitWidth;
            }
            if (value >= 100) {
                width = threeDigitWidth;
            }
            style = {color: green, width: width + '%', textAlign: 'right', marginLeft: '15px'}
            return <div style={style}>
                {value?.toLocaleString()}
            </div>
        } else if (value < minValue) {
            width = calculatePercentage(maxValue, minValue, minValue);
            style = {color: red, width: width + '%', textAlign: 'right', marginLeft: '-15px'}
            return <div style={style}>
                {value?.toLocaleString()}
            </div>
        } else if (value > maxValue) {
            width = calculatePercentage(maxValue, maxValue, minValue);
            style = {color: red, width: width + '%', textAlign: 'right', marginLeft: '15px'}
            return <div style={style}>
                {value?.toLocaleString()}
            </div>
        } else {
            width = calculatePercentage(maxValue, value, minValue);
            style = {color: getLabelColor(width), width: width + '%', textAlign: 'right', marginLeft: minValue !== value ? '10px' : '-10px'}
            return <div style={style}>
                {value?.toLocaleString()}
            </div>

        }
    }

    return (
        <>
            <Row>
                <Col className={'pt-4'} md={1} style={{opacity: 0.7, whiteSpace: 'nowrap'}}>{parseFloat(minValue)?.toLocaleString()}</Col>
                <Col className={setClassByMinValue(minValue)} style={{paddingRight: '9px'}}>
                    {renderLabel()}
                    <div style={{height: '7px'}}>
                        <div className={'d-inline-block'} style={{backgroundColor: red, height: '100%', width: '15%', borderTopLeftRadius: '25px', borderBottomLeftRadius: '25px'}}>
                            <div/>
                        </div>
                        <div className={'d-inline-block'} style={{backgroundColor: yellow, height: '100%', width: '15%'}}>
                            <div/>
                        </div>
                        <div className={'d-inline-block'} style={{backgroundColor: green, height: '100%', width: '40%'}}>
                            <div/>
                        </div>
                        <div className={'d-inline-block'} style={{backgroundColor: yellow, height: '100%', width: '15%'}}>
                            <div/>
                        </div>
                        <div className={'d-inline-block'} style={{backgroundColor: red, height: '100%', width: '15%', borderTopRightRadius: '25px', borderBottomRightRadius: '25px'}}>
                            <div/>
                        </div>
                    </div>
                    {renderArrow()}
                </Col>
                <Col className={'pl-0 pt-4'} style={{opacity: 0.7, whiteSpace: 'nowrap'}} md={1}>{maxValue?.toLocaleString()}</Col>
            </Row>
        </>

    )

}
