import React, {createContext, useReducer} from 'react';
import {SET_REFRESH_RATE, SET_PRODUCTION_LINE, SET_USER_EMAIL} from './ActionTypes/actionTypes'
import Cookies from 'js-cookie';

const initialState = {
    serverUrl: process.env.REACT_APP_API_URL,
    userEmail: null,
    selectedProductionLine: Cookies.get('selectedProductionLine') ? Cookies.get('selectedProductionLine') : null,
    refreshRate: 60000
};

const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ( { children } ) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch(action.type) {
      case SET_REFRESH_RATE:
        return {
          ...state,
          refreshRate: action.payload
        }

      case SET_PRODUCTION_LINE:
        Cookies.set('selectedProductionLine', action.payload);
        return {
          ...state,
          selectedProductionLine: action.payload
        }

      case SET_USER_EMAIL:
        return {
          ...state,
          userEmail: action.payload
        }

      default:
        return new Error();
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider }
