import React, {useContext, useEffect, useRef, useState} from 'react';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { store } from '../../Store/store';
import axios, { handleError } from '../../Axios/axiosConfig'
import moment from 'moment-timezone';
import { FaSync } from 'react-icons/fa';
import FlowrateChartGroup from "../../Components/FlowrateChartGroup/FlowrateChartGroup";
import {Multiselect} from "multiselect-react-dropdown";

import './ValveStatusPage.css'
import {getProductionLineId} from "../../General/general";

export default function ValveStatusPage(props) {
    const globalState = useContext(store);
    const serverUrl = useRef(`${globalState.state.serverUrl}/production_line/${getProductionLineId(globalState)}`);
    const refreshRate = useContext(store).state.refreshRate;

    const [valvesData, setValvesData] = useState([]);
    const [valveMultiselectOptions, setValveMultiselectOptions] = useState([]);
    const [selectedValves, setSelectedValves] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingProductionLine, setIsLoadingProductionLine] = useState(false);
    const [sensorCharts, setSensorCharts] = useState([]);
    const [sensorChartsUnitSymbol, setSensorChartsUnitSymbol] = useState(null);
    const [valveCharts, setValveCharts] = useState([]);

    const selectedValvesRef = useRef(null);

    const currProductionLine = useRef(globalState.state.selectedProductionLine);
    const isInitialLoad = useRef(true);

    const FORMATTER_MULTIPLIER = 1.5;
    const CHART_HEIGHT_PER_SERIES = 60;

    var fromDate, toDate;

    const updateTimeFrame = () => {
        let now = new Date();
        toDate = moment(now).utc().format()
        fromDate = moment(now).utc().subtract(8, 'hours').format()
    }

    const isProductionLineSwitched = () => {
        return currProductionLine.current !== globalState.state.selectedProductionLine;
    }

    // Returns Sensors Flowrates and Statuses of relevant Valves
    const getSensorsAndValves = async () => {
        await axios.get(`${serverUrl.current}/flowDetails/between?fromDate=${fromDate}&toDate=${toDate}`).then(response => {
            setSensorCharts(response.data.sensors.map((sensor, index) => {
                return {
                    name: sensor.label,
                    data: sensor.timeSeriesData
                }
            }))

            setSensorChartsUnitSymbol(response.data.sensors[0]?.unitSymbol);

            setValvesData(response.data.valves);

            let options = [];

            Object.keys(response.data.valves).forEach(valve => {
                options.push({ value: response.data.valves[valve].name, label: `${response.data.valves[valve].name.toUpperCase()} - ${response.data.valves[valve].label}` })
            })

            setValveMultiselectOptions(options);

            // Select All on load finished
            if (!selectedValvesRef.current || selectedValvesRef.current.length === 0) {
                setSelectedValves(options);
            } else {
                setSelectedValves(selectedValvesRef.current)
            }

            isInitialLoad.current= false;
        }).catch(error => handleError(error))
    }

    const valveStatusChartTooltipFormatter = (self) => {
        return [`<b>${moment(self.x).utc().format('llll')}</b>`].concat(self.points ?
            self.points.map((point, index) => {
                return `${point.series.name}: ${point.y - index * FORMATTER_MULTIPLIER !== 0 ? 'Open' : 'Closed'}`;
            }) : [])
    }

    const getAllData = async () => {
        setIsLoading(true);
        updateTimeFrame();

        try {
            await getSensorsAndValves();
        }
        catch(error) {
            handleError(error)
        }
        finally {
            setIsLoading(false)
            setIsLoadingProductionLine(false)
        }
    }

    useEffect(() => {
        selectedValvesRef.current = selectedValves;

        let valveChartsArray = [];

        selectedValves.forEach((valve, index) => {
            let valueFormatter = index * FORMATTER_MULTIPLIER;

            valveChartsArray.push({
                name: valve.label,
                data: (valvesData[valve.value]) ? valvesData[valve.value].timeSeriesData.map(entry => ([entry[0], entry[1] + valueFormatter])) : []
            })
        })

        setValveCharts(valveChartsArray);
    }, [selectedValves])

    useEffect(() => {
        getAllData();
    }, [])

    // Refresh the display every x seconds
    useEffect(() => {
        const intervalId = setInterval(() => {
            getAllData();
        }, refreshRate)

        return () => clearInterval(intervalId);

    }, []);

    useEffect(() => {
        serverUrl.current = `${globalState.state.serverUrl}/production_line/${getProductionLineId(globalState)}`;
        setIsLoadingProductionLine(true);

        setSelectedValves([]);
        selectedValvesRef.current = null;
        setSensorCharts([]);

        getAllData();

        currProductionLine.current = globalState.state.selectedProductionLine;
    }, [globalState.state.selectedProductionLine])

    return (
        <Container fluid className='valve-status-page-container'>
            <Button className="refresh-button" variant="primary" disabled={isLoading} onClick={() => getAllData()}>
                { isLoading ? <Spinner animation="border" size="sm"/> : <FaSync></FaSync> }
            </Button>

            { isLoadingProductionLine && <Spinner animation="border" variant="primary" /> }

            { !isLoadingProductionLine &&
            <>
                <Row style={{marginTop: '-2vh'}}>
                    <Col>
                        <FlowrateChartGroup
                            showLegend
                            charts={sensorCharts}
                            unitSymbol={sensorChartsUnitSymbol}
                            yAxisLabel={'Status'}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div style={{display: 'inline-flex'}}>
                            <Multiselect
                                options={valveMultiselectOptions}
                                selectedValues={selectedValves}
                                style={{searchBox: {width: '50vh'}, optionContainer: {width: '50vh'}, chips: {visibility: 'hidden', position: 'absolute'}}}
                                customCloseIcon={""}
                                showCheckbox
                                closeOnSelect={false}
                                placeholder={selectedValves.length > 0 ? `${selectedValves.length} Selected` : 'Select Valves'}
                                avoidHighlightFirstOption={true}
                                onSelect={(selectedList) => setSelectedValves(selectedList)}
                                onRemove={(selectedList) => setSelectedValves(selectedList)}
                                displayValue="label" />

                            <Button onClick={() => setSelectedValves(valveMultiselectOptions)} size="sm" style={{marginLeft: '1vh', width: '100px'}}>Select All</Button>
                            <Button onClick={() => setSelectedValves([])} size="sm" style={{marginLeft: '1vh'}}>Clear</Button>
                        </div>

                        <FlowrateChartGroup chartHeight={'20%'} verticalScroll minScrollHeight={CHART_HEIGHT_PER_SERIES * valveCharts.length} charts={valveCharts} lineWidth={4} steps hideYAxis yAxisTitle={'Status'} tooltipFormatter={valveStatusChartTooltipFormatter} />
                    </Col>
                </Row>
            </>
            }
        </Container>
    )
}