import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Row, Spinner} from "react-bootstrap";
import FlowrateChartGroup from "../../Components/FlowrateChartGroup/FlowrateChartGroup";
import {textFilter} from "react-bootstrap-table2-filter";
import DataTable from "../../Components/Beverages/StepReportTable/DataTable";
import RangeIndicator from "../../Components/RangeIndicator/RangeIndicator";
import {FaArrowLeft} from "react-icons/all";
import Badge from "react-bootstrap/Badge";
import AnomaliesModal from "./AnomaliesModal";
import {addAlertMarkers, removeFractionDigits, turnAlertsIntoObjectArray} from "../../General/general";

export default function ProductDetailsPage({tcLineService, setMoreDetailsButtonClicked, selectedRow, totalEfficiencyFormatter, durationFormatter, healthFormatter, selectedProductionLineId,}) {
    const [sensorCharts, setSensorCharts] = useState([]);
    const [sensorChartsUnitSymbol, setSensorChartsUnitSymbol] = useState(null);
    const [sensorResponse, setSensorResponse] = useState([]);
    const filterStyle = {margin: '0 auto', height: 30, fontSize: 12}
    const headerStyle = {fontSize: 15, border: 'none'};
    const colStyle = {fontSize: 15, fontWeight: 'bold', background: 'white', verticalAlign: 'center'};
    const [showModal, setShowModal] = useState(false);
    const [productDetails, setProductDetails] = useState([]);
    const [anomalyData, setAnomalyData] = useState([]);
    const [isAreaAnomaliesRequestInProgress, setIsAreaAnomaliesRequestInProgress] = useState(false);
    const [isProductDetailsRequestInProgress, setIsProductDetailsRequestInProgress] = useState(false);

    const getAreaAnomalies = async (productId, areaId, selectedProductionLineId) => {
        try {
            setAnomalyData([]);
            setIsAreaAnomaliesRequestInProgress(true);
            const response = await tcLineService.getAreaAnomalies(productId, areaId, selectedProductionLineId);
            setIsAreaAnomaliesRequestInProgress(false);
            setAnomalyData(response.data);
        } catch (error) {
            setIsAreaAnomaliesRequestInProgress(false);
            console.error(error);
        }

    }

    const volumeFormatter = (cell, row) => {
        const minVolume = row.minVolume ? removeFractionDigits(row.minVolume / 1000) : 0;
        const volume = cell ? removeFractionDigits(cell / 1000) : 0;
        const maxVolume = row.maxVolume ? removeFractionDigits(row.maxVolume / 1000) : 0;
        return <div className={'pl-5 pr-5'}>
            <RangeIndicator minValue={minVolume} value={volume} maxValue={maxVolume}/>
        </div>
    }

    const tableDurationFormatter = (cell, row) => {
        const minDuration = row.minDuration ? removeFractionDigits(row.minDuration / 60) : 0;
        const duration = cell ? removeFractionDigits(cell / 60) : 0;
        const maxDuration = row.maxDuration ? removeFractionDigits(row.maxDuration / 60) : 0;
        return <div className={'pl-5 pr-5'}>
            <RangeIndicator minValue={minDuration} value={duration} maxValue={maxDuration}/>
        </div>

    }
    const anomaliesFormatter = (cell, row) => {
        if (cell > 0) {
            return <div className='justify-content-center align-items-center'>
                <div className={'d-inline-block'}>
                    {cell}
                </div>
                <div className={'d-inline-block'}>
                    <Button style={{height: '24.5px'}} className='pl-1 pr-0 pt-0 pb-0' onClick={async () => {
                        await getAreaAnomalies(selectedRow.id, row.id, selectedProductionLineId);
                        setShowModal(true);
                    }} size={'sm'} variant={'link'}><h6><Badge pill variant={"danger"}> i</Badge></h6>
                    </Button>
                </div>
            </div>
        } else {
            return cell ? cell : 0;
        }
    }

    const columns = [
        {dataField: 'id', title: 'ID', hidden: true},
        {dataField: 'area', title: 'Area', text: 'area', sort: true, filter: textFilter({style: filterStyle})},
        {dataField: 'actualVolume', title: 'Volume [\u33A5]', formatter: volumeFormatter, sort: true, filter: textFilter({style: filterStyle})},
        {dataField: 'actualDuration', title: 'Valve activation [%]', formatter: tableDurationFormatter, sort: true, filter: textFilter({style: filterStyle})},
        {dataField: 'anomalies', sort: true, title: '# Alerts', formatter: anomaliesFormatter, filter: textFilter({style: filterStyle})},
        {dataField: 'health', title: 'Health', formatter: healthFormatter, sort: true, filter: textFilter({style: filterStyle})}
    ];



    const getVerticalLineTimestamps = (data) => {
        return data.verticalLineTimestamps
    }

    const calculateRecuperatedWater = (selectedRow) => {
        return (selectedRow.recuperatedWater / (selectedRow.freshWaterUsed + selectedRow.recuperatedWater)) * 100;
    }

    const covertLitersToCubicMeters = (selectedRow) => {
        return removeFractionDigits(selectedRow.freshWaterUsed / 1000);
    }

    const getMetersFlowRateData = async (productId, selectedProductionLineId, startDate, endDate) => {
        try {
            const response = await tcLineService.getMetersFlowRateData(productId, selectedProductionLineId, startDate, endDate);
            setSensorResponse(response.data);
            let data = addAlertMarkers(turnAlertsIntoObjectArray(response.data.sensors), response.data);
            setSensorCharts(data.map((sensor, index) => {
                return {
                    name: sensor.label, data: sensor.timeSeriesData, tags: sensor.tags
                };
            }))
            setSensorChartsUnitSymbol(response?.data?.sensors[0]?.unitSymbol)
        } catch (error) {
            console.error(error);
        }

    };

    const getProductDetails = async (productId, selectedProductionLineId) => {
        try {
            setIsProductDetailsRequestInProgress(true);
            const response = await tcLineService.getProductDetails(productId, selectedProductionLineId);
            setIsProductDetailsRequestInProgress(false);
            setProductDetails(response.data);
        } catch (error) {
            setIsProductDetailsRequestInProgress(false);
            console.error(error);
        }

    }


    useEffect(() => {
        getProductDetails(selectedRow.id, selectedProductionLineId);
        getMetersFlowRateData(selectedRow.id, selectedProductionLineId, selectedRow.startDate, selectedRow.endDate);
    }, [])

    return (<Card style={{backgroundColor: '#f5f7fb'}} bsPrefix={'main-card-style'}>
        <AnomaliesModal isAnomaliesRequestInProgress={isAreaAnomaliesRequestInProgress} anomalyData={anomalyData} show={showModal} setShow={setShowModal}/>
        <Card.Body className={'p-0'}>
            <Button variant={"link"} onClick={() => setMoreDetailsButtonClicked(false)}><FaArrowLeft style={{color: 'black', position: 'absolute', left: 20, top: 70}} size={20}/></Button>
            <Row>
                <Col md={5} style={{paddingLeft: '35px'}}>
                    <Card>
                        <Card.Body className={'pl-3 pr-3 pt-2 pb-2'}>
                            <Row className={'pb-3 pt-3'}>
                                <Col>
                                    <div style={{color: "grey", fontWeight: 800, textAlign: 'center'}}>
                                        Product
                                    </div>
                                    <div>
                                        <h5 className={'mt-2'} style={{fontWeight: 'bold', textAlign: 'center'}}>{selectedRow.product}</h5>
                                    </div>
                                </Col>
                                <Col>
                                    <div style={{color: "grey", fontWeight: 800, textAlign: 'center'}}>
                                        Start Date
                                    </div>
                                    <div>
                                        <h5 className={'mt-2'} style={{fontWeight: 'bold', textAlign: 'center'}}>{selectedRow.startDate}</h5>
                                    </div>
                                </Col>
                                <Col>
                                    <div style={{color: "grey", fontWeight: 800, textAlign: 'center'}}>
                                        Duration [HH:MM]
                                    </div>
                                    <div>
                                        <h5 className={'ml-2 mt-2'} style={{fontWeight: 'bold', textAlign: 'center'}}>{durationFormatter(selectedRow.duration)}</h5>
                                    </div>
                                </Col>
                            </Row>
                            <hr/>
                            <Row className={'pt-4 pb-3'}>
                                <Col>
                                    <div style={{color: "grey", fontWeight: 800, textAlign: 'center'}}>
                                        Total Efficiency [L/KG]
                                    </div>
                                    <div>
                                        <div className={'mt-2'} style={{fontWeight: 'bold', whiteSpace: 'nowrap'}}>{totalEfficiencyFormatter(selectedRow.totalEfficiency, selectedRow)}</div>
                                    </div>
                                </Col>
                                <Col>
                                    <div style={{color: "grey", fontWeight: 800, textAlign: 'center'}}>
                                        Weight Produced [KG]
                                    </div>
                                    <div>
                                        <h5 className={'mt-4'} style={{fontWeight: 'bold', textAlign: 'center'}}>{selectedRow.weightProduced.toLocaleString()} </h5>
                                    </div>
                                </Col>
                                <Col>
                                    <div style={{color: "grey", fontWeight: 800, textAlign: 'center'}}>
                                        Health Score
                                    </div>
                                    <div>
                                        <div className={'mt-2'} style={{fontWeight: 'bold'}}>{healthFormatter(selectedRow.health)}</div>
                                    </div>
                                </Col>
                            </Row>
                            <hr/>
                            <Row style={{paddingBottom: '9px'}}>
                                <Col className={'mt-3'}>
                                    <div style={{color: "grey", fontWeight: 800, textAlign: 'center'}}>
                                        Recuperated Ratio Vol
                                    </div>
                                    <div>
                                        <h5 className={'mt-4'} style={{fontWeight: 'bold', textAlign: 'center'}}>{
                                            calculateRecuperatedWater(selectedRow).toLocaleString()
                                        }%</h5>
                                    </div>
                                </Col>
                                <Col className={'mt-3'}>
                                    <div style={{color: "grey", whiteSpace: 'nowrap', fontWeight: 800, textAlign: 'center'}}>
                                        Total Volume Used [<h5 style={{display: 'inline-block'}}>&#13221;</h5>]
                                    </div>
                                    <div>
                                        <h5 className={'mt-4'} style={{fontWeight: 'bold', textAlign: 'center'}}>{covertLitersToCubicMeters(selectedRow).toLocaleString()}</h5>
                                    </div>
                                </Col>
                                <Col className={'mt-3'}>
                                    <div style={{color: "grey", fontWeight: 800, textAlign: 'center'}}>
                                        Alerts Found
                                    </div>
                                    <div>
                                        <h5 className={'mt-4'} style={{fontWeight: 'bold', textAlign: 'center'}}>{selectedRow.anomalies}</h5>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={7}>
                    <Card>
                        <Card.Body className={'p-2'}>
                            <Row>
                                <Col>
                                    {sensorChartsUnitSymbol ?
                                        <FlowrateChartGroup showLegend linked charts={sensorCharts}
                                                                                  unitSymbol={sensorChartsUnitSymbol} yAxisTitle={"Flowrate"}
                                                                                  verticalLineTimestamps={getVerticalLineTimestamps(sensorResponse)}
                                                                                  showSpinnerOnLoad plotlines={true}/>
                                        :
                                        <Spinner animation={"border"} variant={'primary'}/>}

                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className={'pt-2'}>
                <Col>
                    {!isProductDetailsRequestInProgress ? <DataTable
                        headerStyle={headerStyle}
                        colStyle={colStyle}
                        data={productDetails}
                        columns={columns}
                        pageSize={productDetails.length > 5 ? 4 : 5}
                        paginationSize={10}
                    /> : <Spinner animation='border' variant='primary'/>}

                </Col>
            </Row>
        </Card.Body>
    </Card>)
}
