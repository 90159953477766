import React, {useContext, useEffect, useRef, useState} from 'react';
import {InputGroup, FormControl} from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import Spinner from "../Spinner/Spinner";
import axios from "../../Axios/axiosConfig";
import {store} from "../../Store/store";

import "./StageTargetTable.css"
import {getProductionLineId} from "../../General/general";

export default function StageTargetTable(props) {
    const globalState = useContext(store);
    const serverUrl = useRef(`${globalState.state.serverUrl}/${getProductionLineId(globalState)}`);

    const [columns, setColumns] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const headerStyle = { fontSize: 16, margin: '-10px' }
    const colStyle = { fontSize: 14, padding: '10px', verticalAlign: 'center', textAlign: 'center'}

    const cellFormatter = (cell, row) => {
        if (!cell) return <></>;

        return (
            <FlowrateEditor value={cell} isWintUser={() => isWintUser()}/>
        )
    }

    const isWintUser = () => {
        return (globalState.state.userEmail.endsWith("@wint.ai"));
    }

    useEffect(() => {
        setIsLoading(true);

        if (!props.data || !props.data.stages || Object.keys(props.data.stages).length === 0) {
            setColumns([]);
            setTableData([]);
        }
        else {
            let columns = [{
                dataField: 'label',
                text: 'Product',
                editable: false,
                headerStyle: { fontSize: 16, width: '15%', margin: '-10px' },
                style: { fontSize: 20, fontWeight: 700, padding: '10px', verticalAlign: 'middle'}
            }];

            Object.keys(props.data.stages || []).forEach(stageId => {
                columns.push({
                    key: stageId,
                    dataField: stageId,
                    text: `Stage ${props.data.stages[stageId]}`,
                    // editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => {
                    //     if (!value) return <></>;
                    //
                    //     return <FlowrateEditor key={`flowrate-editor-${value.productId}-${value.stageId}`} isWintUser={() => isWintUser()} {...editorProps} value={value}/>
                    // },
                    headerStyle: headerStyle,
                    style: colStyle,
                    formatter: cellFormatter
                });
            })

            setColumns(columns);

            let tableData = [];

            (props.data.products || []).forEach(product => {
                let rowData = { id: product.id, label: product.label };

                Object.keys(product.stages).forEach(stageId => {
                    rowData[stageId] = product.stages[stageId];
                })

                tableData.push(rowData);
            })

            setTableData(tableData);
        }

        setIsLoading(false);

    }, [props.data])

    const cellEdit = cellEditFactory({
        mode: 'click',
        blurToSave: true,
        beforeSaveCell: (oldValue, newValue, row, column) => {
            axios.post(`${serverUrl.current}/targetFlowRate/`, newValue)
        }
    });

    return (
        <>
            { isLoading && <Spinner variant="primary" animation="border" /> }
            { !isLoading && columns.length === 0 && <h5>No Data to display</h5>}
            {
                (columns && columns.length > 0) &&
                <>
                    <h5>Target Flowrate per Product by Stage</h5>
                    <BootstrapTable
                        keyField={'id'}
                        columns={columns}
                        data={tableData}
                        wrapperClasses="table-responsive"
                        // cellEdit={cellEdit}
                    />
                </>
            }
        </>
    )
}

function FlowrateEditor(props) {
    const globalState = useContext(store);
    const serverUrl = useRef(`${globalState.state.serverUrl}/${getProductionLineId(globalState)}`);

    const flowRateEditorRef = useRef();
    const minFlowRateEditorRef = useRef();
    const maxFlowRateEditorRef = useRef();

    const textBoxStyle = {textAlign: 'center', fontWeight: 600 };
    const inputGroupStyle = {width: '20vh', textAlign: 'center', margin: '0 auto'};

    const getValue = () => {
        return {
            "id": props.value.id,
            "productId": props.value.productId,
            "stageId": props.value.stageId,
            "flowRate": flowRateEditorRef?.current.value,
            "minFlowRate": minFlowRateEditorRef?.current.value,
            "maxFlowRate": maxFlowRateEditorRef?.current.value
        };
    }

    const validateNumericalInput = (ref) => {
        let regExp = /[a-zA-Z]/g;

        if (regExp.test(ref.current.value)) {
            ref.current.value = ref.current.value.replace(/[^\d.-]/g, '');
        }

        // Remove leading zeroes
        if (ref.current.value.startsWith('0') && ref.current.value !== '0') {
            ref.current.value = ref.current.value.replace(/^0+/, '');
        }
    }

    const updateField = (ref, defaultValue) => {
        flashingTextBox(ref, defaultValue);
        axios.post(`${serverUrl.current}/targetFlowRate/`, getValue())
    }

    const flashingTextBox = (ref, defaultValue) => {
        if (defaultValue.toString() === ref.current.value) return;

        let previousClassName = ref.current.className;
        ref.current.className += ' blinking';
        setTimeout(() => { ref.current.className = previousClassName }, 600);
    }

    const flowRateEditor = <InputGroup key={`container-1-${props.value.productId}-${props.value.stageId}`} className="mb-3" style={inputGroupStyle}>
        <InputGroup.Text id={`basic-addon1-${props.value.productId}-${props.value.stageId}`}>Target</InputGroup.Text>
        <FormControl
            onChange={() => validateNumericalInput(flowRateEditorRef)}
            defaultValue={props.value?.flowRate}
            key={"flowRateEditor"}
            ref={ flowRateEditorRef }
            placeholder="Target"
            aria-label="Target"
            aria-describedby={`basic-addon1-${props.value.productId}-${props.value.stageId}`}
            style={textBoxStyle}
            onBlur={() => updateField(flowRateEditorRef, props.value?.flowRate)}
        />
    </InputGroup>

    const minFlowRateEditor = props.isWintUser ?
        <InputGroup key={`container-2-${props.value.productId}-${props.value.stageId}`} className="mb-3" style={inputGroupStyle}>
            <InputGroup.Text id={`basic-addon2-${props.value.productId}-${props.value.stageId}`}>Min</InputGroup.Text>
            <FormControl
                onChange={() => validateNumericalInput(minFlowRateEditorRef)}
                defaultValue={props.value?.minFlowRate}
                key={"minFlowRateEditor"}
                ref={ minFlowRateEditorRef }
                placeholder="Minimum"
                aria-label="Minimum"
                aria-describedby={`basic-addon2-${props.value.productId}-${props.value.stageId}`}
                style={textBoxStyle}
                onBlur={() => updateField(minFlowRateEditorRef, props.value?.minFlowRate)}
            />
        </InputGroup> : <></>

    const maxFlowRateEditor = props.isWintUser ?
        <InputGroup key={`container-3-${props.value.productId}-${props.value.stageId}`} className="mb-3" style={inputGroupStyle}>
            <InputGroup.Text id={`basic-addon3-${props.value.productId}-${props.value.stageId}`}>Max</InputGroup.Text>
            <FormControl
                onChange={() => validateNumericalInput(maxFlowRateEditorRef)}
                defaultValue={props.value?.maxFlowRate}
                key={"maxFlowRateEditor"}
                ref={ maxFlowRateEditorRef }
                placeholder="Maximum"
                aria-label="Maximum"
                aria-describedby={`basic-addon3-${props.value.productId}-${props.value.stageId}`}
                style={textBoxStyle}
                onBlur={() => updateField(maxFlowRateEditorRef, props.value?.maxFlowRate)}
            />
        </InputGroup> : <></>

    return [flowRateEditor, minFlowRateEditor, maxFlowRateEditor];
}