import React, {useContext, useEffect, useRef, useState} from 'react';
import {Card, Col, Row} from "react-bootstrap";
import DataTable from "../../Components/Beverages/StepReportTable/DataTable";
import './TortillaChipsHomePage.css';
import moment from "moment/moment";
import {store} from "../../Store/store";
import {renderLogo} from "../../General/general";

export default function TortillaChipsHomePageFullTable(props) {
    const globalState = useContext(store);
    const componentRef = useRef();
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    const getProductionLine = (globalState) => {
        return typeof globalState.state.selectedProductionLine === 'string' ?
            JSON.parse(globalState.state.selectedProductionLine).label :
            globalState.state.selectedProductionLine.label
    }

    const isFilterApplied = () => {
        return props.productFilterValue !== '' ||
            props.startDateFilterValue !== '' ||
            props.durationFilterValue !== '' ||
            props.totalEfficiencyFilterValue !== '' ||
            props.weightProducedFilterValue !== '' ||
            props.healthFilterValue !== '' ||
            props.anomaliesFilterValue !== '' ||
            props.waterUsedFilterValue !== '';

    }

    const [isDownloadButtonClicked, setIsDownloadButtonClicked] = useState(!isFilterApplied());


    const savePDF = () => {
        window.print();
        props.setIsDownloadFinished(true);
    };

    useEffect(() => {
        if (isDownloadButtonClicked && isImageLoaded) {
            savePDF();
        }
    }, [isDownloadButtonClicked, isImageLoaded])


    const headerStyle = {fontSize: 15, border: 'none'}
    const colStyle = {fontSize: 15, fontWeight: 'bold', background: 'white', verticalAlign: 'center'}
    const startDate = props.startDate;
    const endDate = props.endDate;

    return (
        <Card ref={componentRef} style={{backgroundColor: '#f5f7fb'}} bsPrefix={'main-card-style'}>
            <Row>
                <Col>
                    <div style={{textAlign: 'left'}}>
                        {renderLogo(globalState, setIsImageLoaded)}
                    </div>
                </Col>
                <Col>
                    <div style={{textAlign: 'right'}}>{getProductionLine(globalState)}</div>
                    <div style={{textAlign: 'right'}}>{moment(startDate).format('ll')} - {moment(endDate).format('ll')}</div>
                </Col>
            </Row>
            <br/>
            {props.renderWidgets()}
            <Row className={'pt-3'}>
                <Col>
                    <DataTable
                        setIsDownloadButtonClicked={setIsDownloadButtonClicked}
                        isFilterApplied={isFilterApplied()}
                        headerStyle={headerStyle}
                        colStyle={colStyle}
                        data={props.data}
                        columns={props.columns}
                        title={''}
                        pageSize={props.data.length}
                        paginationSize={10}
                    />
                </Col>
            </Row>
        </Card>)

}
