import {Button, Col, Modal, Row, Spinner} from "react-bootstrap";
import React, {useEffect} from "react";
import DataTable from "../../Components/Beverages/StepReportTable/DataTable";

const AlertsModal = ({isAlertsRequestInProgress, alertsData, show, setShow, alertsColumns}) => {
    const colStyle = {fontSize: 15, background: 'white', verticalAlign: 'center', maxWidth: 100, textAlign: 'center'};
    const headerStyle = {fontSize: 15, border: 'none', textAlign: 'center'};

    return (
        <Modal className={'SettingModal'} dialogClassName={'modal-settings'} show={show} backdrop={true}
               onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Alerts Info</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    isAlertsRequestInProgress ?
                        <div style={{textAlign: 'center'}}><Spinner animation={"border"} variant={"primary"}/></div>
                        :
                        <Row className={'pt-2'}>
                            <Col>
                                <DataTable
                                    headerStyle={headerStyle}
                                    colStyle={colStyle}
                                    data={alertsData}
                                    columns={alertsColumns}
                                    paginationSize={10}
                                />
                            </Col>
                        </Row>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => setShow(false)}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AlertsModal;
