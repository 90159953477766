import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { AnomalyStatusEnum } from '../../enums'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import './AnomaliesTable.css'

export default function AnomaliesTable(props) {
    const headerStyle = { fontSize: 10, margin: '-10px' }
    const colStyle = { fontSize: 12, padding: '1px', verticalAlign: 'center'}

    const statusFormatter = (cell, row) => {
        if (row.status === AnomalyStatusEnum.ALERT_ABOVE || row.status === AnomalyStatusEnum.ALERT_BELOW) {
          return (
            <span className="dot dot-red"/>
          );
        } else if (row.status === AnomalyStatusEnum.WARNING_ABOVE || row.status === AnomalyStatusEnum.WARNING_BELOW) {
            return (
                <span className="dot dot-yellow"/>
            )
        } else if (row.status === AnomalyStatusEnum.CLEAR) {
            return (
                <span className="dot dot-green"/>
            )
        }
      }

    var columns = [
        { dataField: 'status', text: 'Status', headerStyle: headerStyle, style: colStyle, formatter: statusFormatter },
        { dataField: 'detectionTime', text: 'Detection Time', headerStyle: headerStyle, style: colStyle,  },
        { dataField: 'endTime', text: 'End Time', headerStyle: headerStyle, style: colStyle,  },
        { dataField: 'message', text: 'Anomalies', headerStyle: headerStyle, style: colStyle,  }
    ]

    if (props.hideStatus) {
        columns = columns.filter(c => { return c.dataField !== 'status' });
    }

    if (props.hideEndTime) {
        columns = columns.filter(c => { return c.dataField !== 'endTime' });
    }

    const paginationOptions = {
        paginationSize: 4,
        pageStartIndex: 1,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        // showTotal: true,
        sizePerPageList: [{
            text: props.pageSize || '4', value: props.pageSize || 4
        }]
    }

    return (
        <>
            <br/>
            <br/>
            <strong>System Alerts</strong>
            <br/>
            <BootstrapTable
                keyField='id'
                data={props.data}
                columns={ columns }
                pagination={ props.data.length !== 0 && paginationFactory(paginationOptions) }
                hover
            />
            { props.data.length === 0 && <><h5>No System Alerts</h5><br /></> }
        </>
    )
}
